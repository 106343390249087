import { ArticleTitle } from "components/components";
import React from "react";
import "./index.less"


const BenefitComponent = ({backgroundImage,title,text,items}) => {
    const backgroundImageStyle = {
        width: "100%",
        height: "auto",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `url(${backgroundImage})`
      };
    return (
        <div style={{ ...backgroundImageStyle }} className="benefitComponent 3xl:px-96 2xl:px-48">
            <div className="content">
                <ArticleTitle title={title} position="center_white" className="text-white textSize-36 xl:pb-4 mb-2"/>
                <span className="textSize-24 text-white xl:pb-4 mb-2 block">{text}</span>
            </div>
            <div className="wrapItem">
                {
                    items&&items.map((ele,index)=>(
                        <div className="item w-[30%]" key={index}>
                            <img src={ele.icons} alt="IconHome" className="lg:w-20 md:w-16 w-10 flex-shrink-0"/>
                            <span className="itemTitle textSize-18 text-green-600">{ele.content}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default BenefitComponent;