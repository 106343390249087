import post1_img1 from "asserts/img/blogs/post_1/b2b.jpg";
import post1_img2 from "asserts/img/blogs/post_1/market.png";
import post2_img1 from "asserts/img/blogs/post_2/b2b.png";
import post3_img1 from "asserts/img/blogs/post_3/img1.jpg";
import post3_img2 from "asserts/img/blogs/post_3/img2.jpg";
import post3_img3 from "asserts/img/blogs/post_3/img3.jpg";
import post4_img1 from "asserts/img/blogs/post_4/img1.png";
import post4_img2 from "asserts/img/blogs/post_4/img2.jpg";
import post4_img3 from "asserts/img/blogs/post_4/img3.jpg";
import post5_img1 from "asserts/img/blogs/post_5/img1.png";
import post5_img2 from "asserts/img/blogs/post_5/img2.png";
import post6_img1 from "asserts/img/blogs/post_6/img1.jpg";
import post6_img2 from "asserts/img/blogs/post_6/img2.jpg";
import post6_img3 from "asserts/img/blogs/post_6/img3.jpg";

const posts = [
    {
    "title":"MẤT CÂN BẰNG CUNG CẦU, BẤT CẬP VỀ GIÁ, BALANCE - GIẢI PHÁP HIỆU QUẢ CHO NỀN NÔNG NGHIỆP VIỆT",
    "id":"5120231",
    "author":"ARIBALANCE",
    "time":"17/11/2022 - 04:38:00",
    "img":post1_img1,
    featured:"MAIN",
    "content":[
        {
            "type":"text",
            "title":"",
            "contentItem":"Cục bộ, chậm phát triển, đầu ra đang bị phụ thuộc, rõ ràng đang là thực trạng của nền nông nghiệp Việt Nam. Nhiều năm qua, nông nghiệp Việt luôn là ngành chịu nhiều chi phối bởi các yếu tố khách quan như thời tiết, đất đai, biến đổi khí hậu…vốn đã rất khó khăn. Bên cạnh đó có thể thấy tình trạng ép giá, hạn chế nguồn thu mua luôn là nỗi ám ảnh với người nông dân. Điệp khúc được mùa mất giá, được giá mất mùa cứ lặp đi lặp lại, những tin tức giải cứu nông sản lại tràn lan trên các phương tiện thông tin đại chúng."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Tuy nhiên, nếu nhìn nhận vấn đề này ở góc độ người tiêu dùng thì khá bất cập, bởi lẽ hàng hóa có tắc nghẽn ở cửa khẩu, hay nằm im lìm trên trang trại thì nó cũng chỉ ở mức tin tức, trong khi đó người tiêu dùng phải đối mặt với vấn đề hàng hóa khan hiếm và đắt đỏ trên thị trường. Thậm chí, họ còn phải đối diện với vấn đề hàng hóa không đảm bảo chất lượng. Đơn cử vụ việc ngày 20/9/2022 vừa rồi, các lô rau sạch mang nhãn Vietgap trên thị trường đã bị thu hồi do không truy xuất được nguồn gốc. Nông sản không rõ nguồn gốc xuất sứ lại mang nhãn dán của thương hiệu nông sản sạch, điều này đã gây nên sự hoang mang cho người tiêu dùng, bởi họ chấp nhận mua nông sản với giá cao đồng nghĩa với việc mua chất lượng đảm bảo, tuy nhiên “tiền mất tất mang”, sản phẩm không rõ nguồn gốc xuất sứ khiến người tiêu dùng mất lòng tin vào nông sản Việt. Rõ ràng những tình trạng này đã tạo ra lỗ hổng mất cân bằng cung cầu trong quá trình sản xuất và phân phối."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Tuy nhiên, nếu nhìn nhận vấn đề này ở góc độ người tiêu dùng thì khá bất cập, bởi lẽ hàng hóa có tắc nghẽn ở cửa khẩu, hay nằm im lìm trên trang trại thì nó cũng chỉ ở mức tin tức, trong khi đó người tiêu dùng phải đối mặt với vấn đề hàng hóa khan hiếm và đắt đỏ trên thị trường. Thậm chí, họ còn phải đối diện với vấn đề hàng hóa không đảm bảo chất lượng. Đơn cử vụ việc ngày 20/9/2022 vừa rồi, các lô rau sạch mang nhãn Vietgap trên thị trường đã bị thu hồi do không truy xuất được nguồn gốc. Nông sản không rõ nguồn gốc xuất sứ lại mang nhãn dán của thương hiệu nông sản sạch, điều này đã gây nên sự hoang mang cho người tiêu dùng, bởi họ chấp nhận mua nông sản với giá cao đồng nghĩa với việc mua chất lượng đảm bảo, tuy nhiên “tiền mất tất mang”, sản phẩm không rõ nguồn gốc xuất sứ khiến người tiêu dùng mất lòng tin vào nông sản Việt. Rõ ràng những tình trạng này đã tạo ra lỗ hổng mất cân bằng cung cầu trong quá trình sản xuất và phân phối."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Vốn dĩ nguyên căn của vấn đề này nằm ở việc nông nghiệp Việt trước giờ đều phụ thuộc hầu hết vào các thương lái. Có thể dễ dàng nhận thấy người nông dân sẵn sàng chấp nhận những điều khoản thiệt thòi để giải quyết nguồn thu mua, trong khi đó các doanh nghiệp phân phối lại giữ thói quen tìm nguồn cung ứng qua các nhà buôn. Điều này dẫn đến bất cập về giá, vì hàng hoá phải qua tay nhiều khâu trung gian rồi mới đến được nhà máy chế biến, lúc đấy giá đã đã bị đội lên rất nhiều lần. Tuy nhiên vấn đề này nằm ở văn hoá buôn bán của nông nghiệp Việt."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Thứ nhất, nét tính cách nổi bật của nông dân Việt là ngây thơ và thụ động. Người nông dân thường chọn cách đợi thương lái tìm đến với nông sản của mình hay tìm đến những nguồn thu mua quen thuộc với tâm lý khách quen, mà ngại việc tìm kiếm những nguồn thu mới, không có cái nhìn tổng quát về thị trường, điều này dẫn đến tình trạng ép giá."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Thứ hai, các doanh nghiệp Việt thường lựa chọn thương lái để mua hàng, để có thể đơn giản hóa trong công tác thu mua và kiểm duyệt, tuy nhiên để đến được tay doanh nghiệp, thì sản phẩm đã qua rất nhiều thương lái và giá cả sẽ chênh lệch đi, vì vậy giá cả sẽ bị đội lên nhiều lần so với thu mua tại vườn."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Đa dạng hóa doanh nghiệp thu mua sẽ giảm thiểu tối đa khả năng bất bình ổn giá. Vấn đề ở đây chính là phương thức tiếp cận. Người lao động biết rõ với nhiều nguồn thu mua, họ sẽ bớt đi gánh nặng về ép giá, doanh nghiệp cũng biết rõ, với nhiều nguồn cung cấp họ sẽ chủ động hơn trong quá trình chế biến và cung cấp hàng hóa. Tuy nhiên mối quan hệ giữa người nông dân và các doanh nghiệp Việt trước giờ không mấy khả quan. "
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Các doanh nghiệp ngại việc tìm kiếm và tạo mối quan hệ với các nông trại bởi rất tốn thời gian, đồng thời sự gắn bó không bền vững về các vấn đề thu mua, sản lượng, và ép giá giữa hai bên, vì vậy doanh nghiệp dễ rơi vào tình trạng thiếu nguồn cung tức thời, nếu như người nông dân không đủ kiên nhẫn, rời bỏ doanh nghiệp hay mất mùa, thất thu."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Bên cạnh đó, vào thời điểm được mùa, nông sản làm ra nhiều, không có đầu ra, thì doanh nghiệp quay lưng, chọn cách làm bảo thủ, thu mua từ các thương lái thay vì tìm đến các nông trại, hoặc lợi dụng ép giá, thêm việc lằng nhằng giấy tờ, khiến người nông dân không mấy tha thiết, lòng tin bị bào mòn từ mùa này qua vụ nọ, nên mối quan hệ này dường như không còn mấy mặn mà."
        },
        {
            "type":"italics",
            "title":"",
            "contentItem":"Vậy đâu là giải pháp cho vấn đề dai dẳng này?"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Mang theo ý tưởng là cầu nối giữa các doanh nghiệp sản xuất và các doanh nghiệp phân phối, cùng với sứ mệnh xóa bỏ tình trạng bất cân bằng cung cầu, góp phần thúc đẩy quá trình chuyển đổi số nền nông nghiệp. Hệ thống Balance đã được hình thành dựa trên sự cam kết nâng cao hiệu quả tiêu thụ nông sản cho nông dân và cung cấp nông sản với giá hợp lý cho doanh nghiệp và người tiêu dùng cuối cùng. "
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Hệ thống Balance được xây dựng như một mô hình trung gian giữa người nông dân và các doanh nghiệp chế biến, phân phối. Đây là ví dụ điển hình cho mô hình B2B ở Việt Nam."
        },
        {
            "type":"img",
            "title":"",
            "contentItem":post1_img1
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Nắm bắt được xu hướng kinh doanh và nhìn nhận được những bất cập của nền nông nghiệp Việt. Hệ thống Balance được xây dựng như một điều tất yếu. Theo đó hệ thống sẽ thực hiện theo 2 quy trình chính đó là tìm kiếm nguồn cung phù hợp và kết nối với doanh nghiệp phân phối. Balance trực tiếp đến các trang trại, tìm nguồn cung ứng sản phẩm nông nghiệp đạt chuẩn chất lượng để đưa lên sàn thương mại và tạo cầu nối đến các doanh nghiệp."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Cụ thể, hệ thống Balance được xây dựng như một sàn thương mại điện tử mà ở đó người dùng sẽ bao gồm hai đối tượng, thứ nhất là các nông dân, trang trại, hợp tác xã, họ sẽ đưa các sản phẩm của mình sau khi qua được khâu kiểm duyệt chất lượng lên sàn để giới thiệu các sản phẩm của mình đến với các doanh nghiệp. Thứ hai là các doanh nghiệp, các doanh nghiệp với nhu cầu tìm kiếm các nguồn cung, bằng cách đăng ký tài khoản trên sàn và thực hiện thao tác tìm kiếm, hệ thống sẽ hỗ trợ và cho ra nhiều kết quả tương ứng. Sau khi tìm kiếm đủ các sản phẩm như nhu cầu, hệ thống sẽ hỗ trợ hai bên ký kết hợp đồng. Hợp đồng sẽ đảm bảo quá trình giao dịch buôn bán giữa hai bên diện ra theo đúng giao hẹn."
        },
        {
            "type":"img",
            "title":"",
            "contentItem":post1_img2
        },
        {
            "type":"list",
            "title":"Việc áp dụng hệ thống Balance vào nông nghiệp Việt sẽ là một hướng giải quyết hiệu quả cho những vấn đề còn tồn tại trong quá trình sản xuất và phân phối:",
            "contentItem":[
                "Thứ nhất, giải pháp lớn nhất mà Balance mang lại cho người nông dân là nguồn thu, nếu như trước đây người dân lao đao không tìm được nguồn thu thì bây giờ sản phẩm lên sàn thương mại sẽ được tiếp cận với rất nhiều doanh nghiệp phân phối, sản phẩm vì vậy tăng được khả năng cạnh tranh, giá cả cũng đa dạng, mang tính thị trường hơn. Thêm nữa, việc hợp tác với nhiều doanh nghiệp, sẽ góp phần thay đổi nét văn hóa mua bán thụ động của người Việt, đồng thời củng cố thêm niềm tin của nông dân Việt vào các doanh nghiệp.",
                "Thứ hai, đối với doanh nghiệp, với việc đa dạng hóa sản phẩm trên sàn thương mại, sự lựa chọn sản phẩm của các doanh nghiệp vì thế mà cũng đã dạng hơn. Giảm thiểu tối đa khả năng thiếu nguồn cung, đồng thời giá cả cũng không bị đội lên nhiều lần khi qua nhiều thương lái như trước đây.",
                "Thứ ba, việc trao đổi mua bán qua hệ thống Balance sẽ hạn chế được nguy cơ buôn bán gian lận từ các thương lái như trước đây. Hệ thống sẽ đảm bảo được chất lượng đầu vào cùng với đó là đánh giá độ uy tín của các doanh nghiệp phân phối.",
                "Ngoài ra, sản phẩm trải qua khâu kiểm duyệt trước khi lên sàn, sẽ đảm bảo được chất lượng nông sản đầu vào, tránh được các tình trạng buôn bán gian lận, sản phẩm  đến tay người tiêu dùng sẽ chất lượng. Đồng thời Balance sẽ tích hợp các bên thứ ba liên quan để hỗ trợ truy suất nguồn gốc trong tương lai, sản phẩm đến tay người tiêu dùng phải là sản phẩm tốt nhất với nguồn gốc rõ ràng."
            ]
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Để hiện thực hóa dự án, hiện nay hệ thống đã kết nối với hơn 200 doanh nghiệp, thông qua liên minh hợp tác xã Hồ Chí Minh đảm bảo bước đầu tiên của hệ thống. Bên cạnh đó, Balance vẫn đang được cải tiến để trở thành giải pháp tối ưu hơn cho nền nông nghiệp Việt."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Trong thời đại mà công nghệ len lỏi vào từng ngóc nhà, từng thôn xóm, mọi hoạt động trong đời sống hằng ngày hầu như đều được hỗ trợ bằng máy móc. Thì không còn yếu tố bất ngờ khi mà một nền nông nghiệp dần được cải thiết bằng công nghệ. Và sẽ một là một bất cập lớn nếu như các doanh nghiệp sản xuất vẫn đau đầu tìm nguồn thu, trong khi đó các doanh nghiệp phân phối thì đang loay hay tìm kiếm nguồn cung phù hợp. Một nền nông nghiệp chuyển đổi số là nền nông nghiệp mà các doanh nghiệp sản xuất biết nắm bắt công nghệ để giảm thiểu tối đa rủi ro trong sản xuất và chủ động trong việc lựa chọn đầu ra phù hợp với giá trị sản phẩm của mình. Một nền nông nghiệp hiện đại là nền nông nghiệp mà các doanh nghiệp chế biến, phân phối biết tìm đến các sàn thương mại để đảm bảo nguồn cung đạt chuẩn, đồng thời củng cố niềm tin với nông dân Việt."
        }
    ]
    },
    {
    "title":"SỰ TÁC ĐỘNG CỦA CÔNG NGHỆ VÀ ĐẠI DỊCH COVID-19 ĐẾN XU HƯỚNG TIÊU DÙNG Ở VIỆT NAM - HIỆN THỰC HÓA ẤP Ủ TRÊN GIẤY",
    "img":post2_img1,
    "id":"5120232",
    "author":"ARIBALANCE",
    "time":"17/11/2022 - 04:38:00",
    "content":[
        {
            "type":"text",
            "title":"",
            "contentItem":"Theo nghiên cứu của Kantar Worldpanel cho thấy, trong thời kỳ của Covid-19, hoạt động ăn uống bên ngoài sụt giảm mạnh. Chi tiêu cho tiêu dùng bên ngoài gia đình giảm 27% và 40% giá trị mất đi đó được chuyển thành chi tiêu cho tiêu dùng tại nhà. Vậy nên, thực phẩm tươi sống được ưa chuộng nhiều hơn trên các gian hàng trực tuyến do người dân bị hạn chế trong thời gian cách ly xã hội."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Lĩnh vực tiêu dùng và ăn uống, dịch bệnh Covid-19 đã và đang ảnh hưởng nặng nề đến chuỗi cung ứng trong lĩnh vực nông sản. Lượng nông sản đã bị trì trệ khi một lượng lớn người tiêu dùng đổ xô đến các siêu thị, chợ, bách hóa xanh để trữ đồ ăn tại nhà phòng khi dịch bùng phát. Cho đến khi Covid-19 đã có được sự ổn định sau dịch, người tiêu dùng lại quay trở về với cuộc sống sinh hoạt hằng ngày, cuốn vào cái “guồng” của công việc. Một bộ phận người tiêu dùng đặc biệt là những phụ nữ có trong tay cả gia đình và nghề nghiệp hay những nhân viên văn phòng làm việc cả ngày đến nỗi không có thời gian để bước chân vào các siêu thị, chợ, bách hóa xanh để tự tay lựa chọn cho mình những loại thực phẩm tươi, sạch với một giá cả hợp lý."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Ngoài ra, hành vi của người tiêu dùng hậu Covid-19 với những đặc điểm như quan tâm và tìm kiếm cho mình các loại thực phẩm sạch, đạt chuẩn yêu cầu vệ sinh an toàn thực phẩm để chăm sóc và bảo vệ sức khỏe, tăng cường sức đề kháng của bản thân cũng như của gia đình họ. Cùng với đó là một chế độ ăn uống cân bằng nhưng đồng thời phải tiện lợi."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Vậy nên điều này cho thấy đại bộ phận người tiêu dùng đều có xu hướng tìm kiếm cho mình một nguồn cung cấp thực phẩm sạch, đảm bảo chất lượng về vấn đề vệ sinh an toàn thực phẩm, đã được qua các khâu kiểm duyệt và kiểm nghiệm, nhưng họ lại không muốn mất quá nhiều thời gian để tìm hiểu và lựa chọn kỹ càng, nên điều quan trọng nhất là phải tạo lòng tin tưởng tuyệt đối đối với nguồn nguyên liệu được đem đến tay của người tiêu dùng sản phẩm này."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Thứ nhất, nét tính cách nổi bật của nông dân Việt là ngây thơ và thụ động. Người nông dân thường chọn cách đợi thương lái tìm đến với nông sản của mình hay tìm đến những nguồn thu mua quen thuộc với tâm lý khách quen, mà ngại việc tìm kiếm những nguồn thu mới, không có cái nhìn tổng quát về thị trường, điều này dẫn đến tình trạng ép giá."
        },
        {
            "type":"title",
            "title":"",
            "contentItem":"MÔ HÌNH B2B"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Sức ép về cạnh tranh, thanh lọc ngày càng gia tăng cùng với cú sốc về kinh tế – xã hội do đại dịch COVID-19 gây ra khiến nhiều doanh nghiệp không khỏi chao đảo. Rất nhiều ngành nghề bị ảnh hưởng trực tiếp như giáo dục, du lịch, hàng không, khách sạn, … rơi vào tình trạng “ngủ đông”, trong đó Food and Beverag (F&B) được biết đến là loại hình dịch vụ ẩm thực trong nhà hàng, khách sạn, khu du lịch và quầy ăn uống.  F&B đang là ngành bị ảnh hưởng trực tiếp. Trong bối cảnh đó, nhiều doanh nghiệp phải tạm dừng hoạt động, thu hẹp quy mô, đặc biệt là những doanh nghiệp có quy mô vừa và nhỏ."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Vấn đề quản lý nguyên vật liệu cũng là một bài toán khó cho các chủ doanh nghiệp. Làm thế nào để đảm bảo số lượng nguyên vật liệu sử dụng trong ngày, không dư thừa quá nhiều khiến nguyên vật liệu hư hỏng. Nhiều doanh nghiệp mặc dù đã lên phương án giảm nhập nguyên vật liệu nhưng đối với mặt hàng dùng trong ngày hoặc ngắn ngày không tiêu thụ hết, bỏ đi cũng làm thất thoát không ít ngân sách."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Theo nghiên cứu cho thấy, số liệu doanh thu của ngành F&B hậu Covid-19 nửa đầu tháng 4/2022 có mức tăng trưởng đạt gần 40% so với cùng kỳ quý 1/2022 và tăng gấp 2 lần so với cùng kỳ quý 4/2021, đó là một dấu hiệu đáng mừng cho các nhà phân phối nguồn nguyên liệu đầu vào cho doanh nghiệp."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Vậy nên, việc nhập về một số lượng lớn nguồn nguyên vật liệu mà không tìm được cách quản lý đảm bảo với một số lượng vừa đủ dẫn đến việc các doanh nghiệp sợ rằng mình không đủ khả năng chi trả về lâu dài. Nhưng từ số liệu trên cho thấy, việc ngành hàng F&B gia tăng trở lại đã khiến các doanh nghiệp có phần nào yên tâm hơn về lượng tiêu dùng của các nguồn nguyên liệu thực phẩm mà mình đã nhập về để dự trữ."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Ngoài ra, hậu Covid-19, đối mặt với việc ý thức hơn về vệ sinh chung và vệ sinh thực phẩm của người tiêu dùng dẫn đến việc yêu cầu khắt khe hơn về việc đảm bảo của các thực phẩm xanh, sạch. Điều này khiến doanh nghiệp phải chú trọng hơn vào khâu kiểm duyệt vệ sinh an toàn thực phẩm đối với những nguồn nguyên liệu mà mình nhập vào."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Cùng lúc đó, các nhà phân phối, việc các doanh nghiệp tất bật tìm cho mình một nguồn cung nguyên liệu đầu vào trực tiếp mà không cần qua các khâu trung gian để giảm thiểu các nguồn ngân sách là điều cần thiết cần chú trọng trong giai đoạn hậu Covid-19."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Mối quan hệ B2B nảy sinh từ đây, và thị trường thương mại điện tử B2B còn đang khá mới mẻ đối với cung cầu nông sản."
        },
        {
            "type":"img",
            "title":"",
            "contentItem":post2_img1
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Nắm bắt được xu hướng kinh doanh và nhìn nhận được những bất cập của nền nghiệp Việt. Hệ thống Balance được xây dựng như một điều tất yếu. Theo đó hệ thống sẽ thực hiện theo 2 quy trình chính đó là tìm kiếm nguồn cung phù hợp và kết nối với doanh nghiệp phân phối. Đội ngũ Balance đã trực tiếp đến các trang trại, tìm nguồn cung ứng sản phẩm nông nghiệp đạt chuẩn các tiêu chí về chất lượng để đưa lên sàn của mình và tạo cầu nối đến các đơn vị phân phối, bán lẻ,..."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Để hiện thực hóa ý tưởng trên giấy, hiện nay đội ngũ hệ thống đã kết nối với hơn 200 doanh nghiệp, thông qua liên minh hợp tác xã Hồ Chí Minh đảm bảo bước đi đầu tiên của hệ thống. Bên cạnh đó, Balance vẫn đang được cải tiến hằng ngày  để trở thành giải pháp tối ưu cho tương lai hiện đại của nền nông nghiệp Việt."
        }
    ]
    },
    {
    "title":"Thách thức của chuyển đổi số trong nông nghiệp",
    "img":post3_img1,
    "id":"5120233",
    "author":"ARIBALANCE",
    featured:"SUB",
    "time":"17/11/2022 - 04:38:00",
    "content":[
        {
            "type":"text",
            "title":"",
            "contentItem":"Bên cạnh một số cơ hội trong hành trình chuyển đổi số trong nông nghiệp, việc ứng dụng kỹ thuật số vào lĩnh vực nông nghiệp còn có những hạn chế như: không đồng bộ, khá rời rạc, chủ yếu dựa vào sáng kiến ​​riêng lẻ của một số công ty, địa phương,… Việc ứng dụng kỹ thuật số vào nông nghiệp còn hạn chế, đến hết năm 2021 mới có khoảng 2.200/19.000 HTX nông nghiệp thực hiện chuyển đổi số, với gần 2% tổng số hộ nông dân được đào tạo về công nghệ. Những hạn chế này xuất phát từ các trở ngại sau:"
        },
        {
            "type":"title",
            "title":"Nhận thức hạn chế",
            "contentItem":"Nhận thức hạn chế"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Việc ứng dụng công nghệ số trong sản xuất kinh doanh nông nghiệp ở Việt Nam còn khá mới nên nhận thức của hầu hết các địa phương, doanh nghiệp và đặc biệt là người nông dân còn hạn chế, chưa thấy được vai trò, tầm quan trọng và sức ép của việc ứng dụng công nghệ số trong sản xuất nông nghiệp. Điều này gây khó khăn cho việc tiếp cận và ứng dụng công nghệ số trong ngành nông nghiệp."
        },
        {
            "type":"img",
            "title":"Nhận thức người dân còn hạn chế trong chuyển đổi số nông nghiệp",
            "contentItem":post3_img1
        },
        {
            "type":"title",
            "title":"",
            "contentItem":"Cơ sở hạ tầng còn nhiều bất cập"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Việc thực hiện chuyển đổi số trong lĩnh vực nông nghiệp đòi hỏi phải có cơ sở hạ tầng, hệ thống dữ liệu số hóa đồng bộ, hiện đại (cây trồng, vật nuôi, các văn bản chính sách đã được số hóa). Tuy nhiên, hạ tầng kết nối của nước ta còn lạc hậu, chưa đồng bộ, chi phí 3G, 4G còn cao, chưa tạo cơ hội cho nông sản vùng sâu, vùng xa kết nối trực tiếp với hệ thống thương mại điện tử. Do đó, việc tiếp cận công nghệ số và ứng dụng vào sản xuất nông nghiệp và doanh nghiệp gặp nhiều khó khăn."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Hệ thống dữ liệu để tích hợp và chia sẻ tồn tại ít hoặc không đáp ứng được các yêu cầu về kết nối, truyền thông. Hệ thống dữ liệu của Bộ chưa tuân thủ khung kiến ​​trúc chính phủ điện tử, chủ yếu vẫn là phiên bản 1.0."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Việc ứng dụng công nghệ thông tin của các doanh nghiệp, hộ sản xuất phần lớn chưa đáp ứng được nhu cầu chuyển đổi số do chưa có cơ sở dữ liệu lớn phục vụ sản xuất, thiếu minh bạch về nguồn gốc sản phẩm và thiếu kết nối chia sẻ thông tin của các giai đoạn: sản xuất, quản lý, logistics, thương mại nông sản."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Có thể nói, rào cản này đang là trở ngại lớn cho việc triển khai công nghệ số trong ngành nông nghiệp Việt Nam trong những năm tới."
        },
        {
            "type":"img",
            "title":"Hạ tầng áp dụng cho chuyển đổi số trong nông nghiệp còn hạn chế",
            "contentItem":post3_img2
        },
        {
            "type":"title",
            "title":"",
            "contentItem":"Chất lượng nguồn nhân lực chuyên môn còn hạn chế"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Trong nông nghiệp số, ngoài tư liệu sản xuất truyền thống, người nông dân còn phải sử dụng dữ liệu và công nghệ số. Vì vậy, ngoài kỹ năng sản xuất, người nông dân cần thêm kiến ​​thức và kỹ năng về kinh doanh, công nghệ, kỹ thuật số và công nghệ sinh học… Tuy nhiên, số lượng nông dân ở nước ta có trình độ khoa học công nghệ còn ít. "
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Năm 2020, chỉ có 16,3% lao động nông thôn được đào tạo nghề và kỹ thuật. Ngoài ra, hầu hết nông dân chưa được đào tạo bài bản về chuyển đổi số nên khó thao tác và đánh giá hiệu quả. Có thể nói rằng, rào cản này là trở ngại lớn nhất cho chuyển đổi số nông nghiệp trong giai đoạn tới."
        },
        {
            "type":"img",
            "title":"Chỉ 16,3% lao động nông thôn được đào tạo nghề và kỹ thuật",
            "contentItem":post3_img3
        },
        {
            "type":"title",
            "title":"",
            "contentItem":"Chính sách chưa phù hợp"
        },
        {
            "type":"text",
            "title":"Việc áp dụng hệ thống Balance vào nông nghiệp Việt sẽ là một hướng giải quyết hiệu quả cho những vấn đề còn tồn tại trong quá trình sản xuất và phân phối:",
            "contentItem":"Chính sách chuyển đổi số trong nông nghiệp chưa phù hợp và kịp thời so với thực tế. Ví dụ, chính sách tín dụng hỗ trợ nông nghiệp công nghệ cao mới chủ yếu dành cho doanh nghiệp nhưng doanh nghiệp cũng khó tiếp cận do thủ tục rườm rà. Hơn nữa, chưa có chính sách khuyến khích giao dịch điện tử tiếp cận khách hàng quốc tế…"
        },
    ]
    },
    {
    "title":"Hướng đi nào cho chuyển đổi số trong nông nghiệp",
    "img":post4_img1,
    "id":"5120234",
    "author":"ARIBALANCE",
    "time":"1/8/2022 - 04:50:00",
    "content":[
        {
            "type":"text",
            "title":"",
            "contentItem":"Để chuyển đổi số trong nông nghiệp được thuận lợi, cần có sự tham gia, phối hợp của các cơ quan ban ngành từ Trung ương đến địa phương và đặc biệt là người nông dân phải sẵn sàng thay đổi cách nghĩ, cách tiếp cận với khoa học công nghệ. Vì vậy, Nhà nước, Bộ Nông nghiệp và Phát triển nông thôn, các bộ, ban ngành liên quan và đặc biệt là các địa phương cần tập trung thực hiện theo nhóm giải pháp sau:"
        },
        {
            "type":"title",
            "title":"Nâng cao nhận thức người dân",
            "contentItem":"Nâng cao nhận thức người dân"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Trước tiên, phải giúp các địa phương, công ty và đặc biệt là nông dân hiểu được vai trò, tầm quan trọng của việc ứng dụng kỹ thuật số vào sản xuất nông nghiệp. Chỉ khi đó thì các địa phương, công ty và nông dân mới chủ động học hỏi và ứng dụng công nghệ số vào sản xuất, kinh doanh nông nghiệp."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Để làm được điều này, cần đẩy mạnh công tác thông tin, truyền thông, tuyên truyền trên các phương tiện thông tin đại chúng, các hội nghị, hội thảo, các mô hình khuyến nông về vai trò và lợi ích của việc ứng dụng công nghệ số trong quản lý, sản xuất và tiêu thụ nông sản trong bối cảnh hiện nay."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Trên cơ sở đó, nâng cao nhận thức của các chủ thể sản xuất, kinh doanh nông nghiệp, đặc biệt là người nông dân, tạo tiền đề cho quá trình chuyển đổi số của ngành nông nghiệp."
        },
        {
            "type":"img",
            "title":"Tích cực mở các lớp đào tạo, tập huấn nâng cao nhận thức người dân",
            "contentItem":post4_img1
        },
        {
            "type":"title",
            "title":"",
            "contentItem":"Nâng cấp cơ sở hạ tầng"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Giải pháp này sẽ tạo điều kiện thuận lợi cho việc tiếp cận và ứng dụng kỹ thuật số vào lĩnh vực nông nghiệp."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Để đạt được điều này, Bộ NN & PTNT đang phối hợp với Bộ Thông tin và Truyền thông phát triển hạ tầng, kết nối Internet băng rộng chất lượng cao (hạ tầng mạng di động 4G, 5G, hạ tầng kết nối IoT) đến các thôn, bản nhằm nâng cao chất lượng và khả năng tiếp cận dịch vụ viễn thông của người dân; cải thiện khả năng tiếp cận dịch vụ viễn thông, giúp nông dân sử dụng thiết bị máy tính miễn phí (cung cấp Internet không dây) tại các trung tâm xã, điểm sinh hoạt văn hóa cộng đồng, điểm du lịch nông thôn."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Tiến tới, phổ cập hạ tầng kỹ thuật số với mục tiêu mỗi hộ nông dân đều có điện thoại thông minh và cáp quang để truyền thông tin kỹ thuật số đến nông dân."
        },
        {
            "type":"img",
            "title":"Mang Internet đến với người dân",
            "contentItem":post4_img2
        },
        {
            "type":"title",
            "title":"",
            "contentItem":"Đẩy mạnh tiến độ xây dựng hệ thống dữ liệu nông nghiệp"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Đẩy nhanh tiến độ xây dựng hệ thống dữ liệu nông nghiệp, bao gồm dữ liệu về đất đai, cây trồng, vật nuôi, vùng canh tác, người sản xuất, lượng nông sản, dịch vụ và số hóa các văn bản hành chính của Bộ."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Để đáp ứng yêu cầu này, Bộ Nông nghiệp và Phát triển nông thôn cần phối hợp với Bộ Thông tin và Truyền thông xây dựng, chuẩn hóa cơ sở dữ liệu ngành, xây dựng bản đồ số trên nền tảng – dạng dữ liệu đồng bộ để kết nối, chia sẻ và cung cấp dữ liệu mở…"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Đồng thời, khuyến khích các cá nhân, doanh nghiệp số hóa quy trình sản xuất, hướng tới tích hợp và minh bạch hóa sản phẩm bằng hệ thống đọc mã QR."
        },
        {
            "type":"img",
            "title":"Đẩy mạnh xây dựng hệ thống thông tin nông nghiệp",
            "contentItem":post4_img3
        },
        {
            "type":"title",
            "title":"",
            "contentItem":"Nâng cao năng lực tiếp cận công nghệ số nông nghiệp"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Nâng cao trình độ, năng lực tiếp cận, giới thiệu công nghệ số trong sản xuất nông nghiệp cho nông dân. Đầu tiên, các bộ ban ngành cần biên soạn, phổ biến nội dung chuyển đổi số, vận hành dịch vụ số an toàn trên không gian mạng, trong chương trình học các cấp."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Bên cạnh đó, cần phối hợp tổ chức các đợt tập huấn nhằm nâng cao trình độ công nghệ thông tin, chuyển đổi số, hỗ trợ truy xuất nguồn gốc và vận hành. Từ đó, người sản xuất sẽ nâng cao kỹ năng quảng bá sản phẩm, kỹ năng sống, tạo điều kiện cho nông dân tiếp cận trực tiếp với khách hàng, tìm hiểu và nắm bắt nhu cầu thị trường, xu hướng tiêu dùng để tổ chức sản xuất hiệu quả."
        },
        {
            "type":"title",
            "title":"",
            "contentItem":"Xây dựng đường lối chính sách phù hợp hiệu quả"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Hằng năm, xây dựng và hoàn thiện các chính sách phục vụ chuyển đổi số trong nông nghiệp phù hợp, kịp thời, tạo động lực thúc đẩy chuyển đổi số ngành nông nghiệp đúng hướng, hiệu quả."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Chuyển đổi số trong nông nghiệp là bước tiến đầy cơ hội và cũng tiềm ẩn nhiều thách thức, nhưng “không đi không thể đến đích”. Nếu chủ động thực hiện chuyển đổi số trong nông nghiệp sớm sẽ giúp nông nghiệp Việt Nam nắm bắt thời cơ, lợi thế tạo động lực mới cho nông nghiệp, nông thôn phát triển hiệu quả, bền vững."
        },
    ]
    },
    {
    "title":"Gian nan câu chuyện đưa sản phẩm hợp tác xã lên sàn thương mại điện tử",
    "img":post5_img1,
    "id":"5120235",
    "author":"ARIBALANCE",
    "time":"1/9/2022 - 06:00:00",
    "content":[
        {
            "type":"text",
            "title":"",
            "contentItem":"Hai năm trở lại đây, dịch COVID-19 kéo dài làm thay đổi hành vi mua sắm của người tiêu dùng từ trực tiếp sang trực tuyến. Chính vì vậy, thương mại điện tử ngày càng chiếm ưu thế lớn trong lĩnh vực bán lẻ và trở thành sân chơi cho hợp tác xã trong việc tiếp cận khách hàng, tìm đầu ra cho sản phẩm."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Thế nhưng, câu chuyện đưa hàng hóa của hợp tác xã lên sàn thương mại điện tử lại không hề đơn giản."
        },
        {
            "type":"title",
            "title":"Chật vật tìm đầu ra",
            "contentItem":"Chật vật tìm đầu ra"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Ông Lưu Văn Nhiệm - Giám đốc Hợp tác xã Chăn nuôi và Dịch vụ thực phẩm sạch Tín Nhiệm, cho biết đón đầu xu thế thương mại điện tử sẽ ngày càng phát triển và dần thay thế các hình thức mua bán trực tiếp, thời gian qua hợp tác xã đã đầu tư cơ sở vật chất và thay đổi hướng đi nhằm đưa sản phẩm của mình tới tay người tiêu dùng nhanh nhất."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Bởi vậy, dù dịch COVID-10 kéo dài nhưng doanh thu của hợp tác xã vẫn luôn được đảm bảo, lượng hàng tiêu thụ qua sàn thương mại điện tử tăng mạnh. Đây là động lực tiếp sức cho các xã viên của hợp tác xã trong thời điểm này."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Đơn cử như Hợp tác xã kinh doanh dịch vụ hữu cơ An Lỗ (Thừa Thiên-Huế) đã đưa sản phẩm gạo hữu cơ lên một số sàn thương mại điện tử, Facebook. Dù đã hơn một năm nhưng lượng đơn đặt hàng qua sàn thương mại điện tử vẫn còn rất khiêm tốn."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Chia sẻ về vấn đề này, ông Nguyễn Ba - Giám đốc Hợp tác xã dịch vụ hữu cơ An Lỗ cho hay cũng bởi quy mô của hợp tác xã quá nhỏ, kèm với đó là khả năng tài chính hạn hẹp khiến quá trình đầu tư, ứng dụng công nghệ thông tin và triển khai ứng dụng thương mại điện tử của hợp tác xã vẫn còn nhiều hạn chế."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Chính vì vậy, việc bán hàng thông qua thương mại điện tử của hợp tác xã hiện chủ yếu là dừng ở việc báo giá, giới thiệu để khách hàng tìm hiểu sản phẩm và so sánh giá cả. Hơn nữa, phần lớn khách hàng của hợp tác xã hiện nay là đến tận nơi mua sản phẩm."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Đây chỉ là một trong vô vàn những mô hình hợp tác xã đang phải loay hoay tìm đầu ra cho sản phẩm của mình khi gắn liền với công nghệ số. Không ít trường hợp tiến hành đăng ký gian hàng trên sàn đã lâu nhưng đến nay vẫn chưa được xác thực và nhận được phản hồi từ phía các sàn thương mại điện tử."
        },
        {
            "type":"img",
            "title":"Sản phẩm nông nghiệp đa dạng trên sàn thương mại điện tử Balance",
            "contentItem":post5_img1
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Ông Trần An Định - Chủ tịch Liên minh Hợp tác xã tỉnh Hòa Bình, cho biết hiện tại trên 70% hợp tác xã có quy mô vốn nhỏ dưới 1 tỷ đồng nên khó tiếp cận, ứng dụng công nghệ kỹ thuật tiên tiến, mở rộng quy mô sản xuất, nâng cao chất lượng sản phẩm."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Còn theo ông Đỗ Nhân Đạo, Chủ tịch Liên minh Hợp tác xã tỉnh Yên Bái, tỷ lệ hợp tác xã của tỉnh sử dụng thành thạo công nghệ thông tin, kết nối nhóm, đưa sản phẩm lên sàn thương mại điện tử chiếm tỷ lệ chưa cao."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Đặc biệt, số lượng hợp tác xã ứng dụng các phần mềm kế toán, phần mềm khai thuế, quản lý sản xuất, hóa đơn điện tử, chữ ký số, bán hàng còn ít."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Nguyên nhân do năng lực, trình độ về số hóa, công nghệ thông tin của hợp tác xã còn hạn chế, trong khi đội ngũ cán bộ trẻ, có kỹ năng chiếm tỷ lệ rất ít; hạ tầng công nghệ thông tin lạc hậu, thậm chí nhiều hợp tác xã chưa có máy tính, thiết bị kết nối internet."
        },
        {
            "type":"title",
            "title":"",
            "contentItem":"Chủ động nắm bắt"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Thống kê của các sàn thương mại điện tử cho thấy, từ đầu năm 2020 tới nay, lượt truy cập của người dùng trên các sàn thương mại điện tử đạt trung bình tới 30 triệu lượt/tháng; riêng Shopee đạt trên 40 triệu lượt truy cập/tháng."
        },
        {
            "type":"img",
            "title":"Sản phẩm nông nghiệp đa dạng trên sàn thương mại điện tử Balance",
            "contentItem":post5_img2
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Vietnam Post giúp nông dân nâng cao giá trị nông sản nhờ công nghệ."
        },
        {
            "type":"title",
            "title":"",
            "contentItem":"Do đó, nếu các hợp tác xã phát triển kinh doanh trên nhiều sàn thương mại điện tử cùng lúc thì sẽ có cơ hội tiếp cận khách hàng miễn phí mỗi tháng ở mức độ cao."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Đặc biệt, tốc độ phát triển của thương mại điện tử tại Việt Nam trong năm 2021 bằng tốc độ của 5-6 năm trở lại đây cộng lại. Bởi thế, việc phát triển kinh doanh, buôn bán theo hình thức thương mại điện tử là hướng đi hoàn toàn đúng đắn."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Tuy nhiên, để hợp tác xã nắm bắt được xu thế này, các chuyên gia cho rằng các sàn thương mại điện tử cần nâng cấp công nghệ để phù hợp hơn với tình hình thực tế và nhu cầu truy cập lớn hiện nay."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Mặt khác, các sàn thương mại điện tử cần đơn giản hóa quá trình đăng ký gian hàng, xem xét giảm thiểu các giấy tờ và giảm thời gian giao hàng để hạn chế tình trạng khách bỏ dở đơn hàng."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Ông Vũ Quang Phong, Tổng Giám đốc Trung tâm Xúc tiến thương mại và đầu tư (Liên minh Hợp tác xã Việt Nam), nhấn mạnh để đưa mỗi nông dân trở thành một thương nhân, mỗi hợp tác xã phát triển thành một doanh nghiệp ứng dụng công nghệ số, các sàn thương mại điện tử cần tăng cường hỗ trợ các hợp tác xã về việc đào tạo, tập huấn, quảng bá, tiêu thụ sản phẩm."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Về phía các hợp tác xã cần có một tư duy đổi mới để có thể tận dụng được hết mức các lợi ích của thương mại điện tử."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Bởi kinh doanh qua sàn thương mại điện tử không chỉ dừng lại ở thu hoạch và bán mà cần có sự đầu tư vào khâu đóng gói, hậu cần, vận chuyển hay làm hình ảnh quảng bá để cải tiến cách làm và phát triển sản xuất kinh doanh một cách tối ưu."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Nhằm tận dụng lợi thế về bán hàng trên các trang thương mại điện tử, Chủ tịch Liên minh Hợp tác xã Việt Nam Nguyễn Ngọc Bảo khẳng định trước mắt Liên minh Hợp tác xã Việt Nam sẽ làm việc với các địa phương xem các hợp tác xã đáp ứng được các yêu cầu về nhân lực, công nghệ thông tin, khả năng về chất lượng và số lượng hàng hóa."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Ngoài ra, Liên minh Hợp tác xã Việt Nam sẽ hỗ trợ đào tạo các hợp tác xã này một cách chuyên nghiệp, sau đó sẽ từng bước nhân rộng trên cả nước để sản phẩm của hợp tác xã từng bước lên sàn thương mại điện tử thành công."
        },
    ]
    },
    {
    "title":"Thực trạng chuyển đổi số trong nông nghiệp",
    "img":post6_img1,
    "id":"5120236",
    "author":"ARIBALANCE",
    featured:"SUB",
    "time":"1/8/2022 - 04:50:00",
    "content":[
        {
            "type":"title",
            "title":"Cơ hội trong chuyển đổi số trong nông nghiệp",
            "contentItem":"Cơ hội trong chuyển đổi số trong nông nghiệp"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Chuyển đổi số trong nông nghiệp được hiểu là quá trình tích hợp và ứng dụng công nghệ số (dữ liệu lớn, điện toán đám mây, IoT,… ) vào tất cả hoạt động của ngành nông nghiệp, làm thay đổi phương thức quản lý, quy trình sản xuất và tiêu thụ từ truyền thống sang hiện đại, thông minh."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Chuyển đổi số trong nông nghiệp sẽ đem đến nhiều cơ hội như:"
        },
        {
            "type":"title",
            "title":"Giảm thiểu rủi ro và thiệt hại",
            "contentItem":"Giảm thiểu rủi ro và thiệt hại"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Chuyển đổi số trong nông nghiệp giúp ngành nông nghiệp giảm thiểu rủi ro và thiệt hại do biến đổi khí hậu gây ra. Việt Nam là một trong 5 quốc gia chịu ảnh hưởng nặng nề của biến đổi khí hậu: nước biển dâng, hiện tượng El Nino,…"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Trên thực tế, biến đổi khí hậu với sự gia tăng nhiệt độ, thời tiết khắc nghiệt đã tác động trực tiếp đến tất cả lĩnh vực của ngành nông nghiệp như: giảm diện tích đất, giảm lượng nước ngọt phục vụ sản xuất nông nghiệp; cường độ bão gia tăng, nước biển dâng và dịch bệnh bùng phát; giảm đa dạng sinh học…"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Kết quả tất yếu là giảm năng suất, chất lượng, thậm chí mất thu nhập trong nông nghiệp. Việc ứng dụng công nghệ AI (trí tuệ nhân tạo), phân tích dữ liệu vào quản lý rủi ro sẽ giúp cảnh báo sớm (72 giờ trước khi bão đi qua), từ đó, các cấp, các ngành, nông dân địa phương sẽ có các biện pháp ứng phó, hạn chế rủi ro do biến đổi khí hậu, sản xuất nông nghiệp hiệu quả và bền vững hơn."
        },
        {
            "type":"img",
            "title":"Theo dõi thời tiết giúp người nông dân chủ động hơn trong canh tác nông nghiệp",
            "contentItem":post6_img1
        },
        {
            "type":"title",
            "title":"",
            "contentItem":"Nâng cao năng suất, tiết kiệm nguồn lực"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Chuyển đổi số trong nông nghiệp giúp ngành nông nghiệp nâng cao năng suất, chất lượng, hiệu quả trong sản xuất và tiêu thụ nông sản. Việc ứng dụng  Internet vạn vật (IoT), dữ liệu lớn và công nghệ sinh học đã giúp phân tích dữ liệu về môi trường, loại đất, cây trồng và giai đoạn trưởng thành của cây trồng."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Dựa trên các dữ liệu được cung cấp, người sản xuất sẽ đưa ra các quyết định phù hợp (bón phân, tưới nước, phun thuốc trừ sâu, thu hoạch …), từ đó giảm chi phí, giảm ô nhiễm tài nguyên nước và đất, bảo vệ đa dạng sinh học."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Thực tế cũng cho thấy, việc ứng dụng kỹ thuật số vào sản xuất nông nghiệp đã giảm một nửa chi phí và công sức lao động, giảm 50% lượng khí thải gây hiệu ứng nhà kính, tăng năng suất lên 30%, từ đó nâng cao thu nhập của nông dân. Hơn nữa, việc tích hợp và ứng dụng công nghệ số vào sản xuất giúp người tiêu dùng có thể truy cập, giám sát các thông số này theo thời gian thực và yên tâm về chất lượng nông sản."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Ngoài ra, ứng dụng kỹ thuật số trong nông nghiệp góp phần tăng cường liên kết giữa người sản xuất và người tiêu dùng, giữa cung và cầu, hạn chế tình trạng “được mùa, mất giá”, từ đó nâng cao sản xuất nông nghiệp theo hướng hiệu quả và bền vững hơn."
        },
        {
            "type":"img",
            "title":"Ứng dụng công nghệ giúp tự hóa việc chăm sóc cây trồng",
            "contentItem":post6_img2
        },
        {
            "type":"title",
            "title":"",
            "contentItem":"Quản lý đồng bộ dễ dàng"
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Việc chuyển đổi số trong nông nghiệp giúp cho việc quản lý và điều hành của ngành nói chung và doanh nghiệp nói riêng hiệu quả hơn. Đối với Bộ Nông nghiệp và Phát triển nông thôn, việc ứng dụng công nghệ thông tin và số hóa trong điều hành, quản lý sẽ giúp ra quyết định nhanh hơn, chính xác hơn nhờ hệ thống thông tin báo cáo kịp thời, tăng hiệu quả quản lý và hiệu quả hoạt động."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Đối với các doanh nghiệp kinh doanh nông sản, công nghệ số cũng đang giúp tăng hiệu quả hoạt động, giảm chi phí vận hành và tiếp cận nhiều khách hàng hơn. Nhờ đó, hiệu quả hoạt động và năng lực cạnh tranh của doanh nghiệp được nâng cao."
        },
        {
            "type":"text",
            "title":"",
            "contentItem":"Vì vậy, chuyển đổi số trong nông nghiệp được xác định là tạo môi trường, lấy nông nghiệp số sinh thái làm nền tảng, xây dựng thể chế, thúc đẩy chuyển đổi từ “sản xuất nông nghiệp” sang “kinh tế nông nghiệp” với mục tiêu cuối cùng là phát triển hiệu quả và bền vững nông nghiệp."
        },
        {
            "type":"img",
            "title":"Chuyển đổi số nông nghiệp giúp điều hành và quản lý dễ dàng",
            "contentItem":post6_img3
        },
    ]
    },
    
]

export default posts;