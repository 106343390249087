import React from "react";
import Layout from "./layout";


function App() {
    return (
        <div className="App">
            <header className="max-w-[2200px] m-auto">
                <Layout/>
            </header>
        </div>
    );
}

export default App;
