import React from 'react'
import icon1 from "asserts/icons/store-operation.svg"
import icon2 from "asserts/icons/store-operation-1.svg"
import icon3 from "asserts/icons/store-operation-2.svg"
import icon4 from "asserts/icons/store-operation-3.svg"
import icon5 from "asserts/icons/store-operation-4.svg"
import line from "asserts/img/Line_14.png"
import "./index.less";
import { ArticleTitle } from 'components/components';
export default function OperationsMobile(props) {
 const contentIcon = (iconLink) => {
  return (
    <>
      <div className="icon-content bg-green-600" >
        <img src={iconLink} alt="" className='md:w-auto w-6'/>
      </div>
    </>
  )
 }
 const contentText = (text) => {
  return (
    <div className='text-content'>
      <span className="textSize-16 text-green-600">{text}</span>
    </div>
      )
 }
 const contentNumber = (num) => {
  return (
    <div className="content-number" style={{backgroundColor: "#F9B509", color: "white"}}>
            <span>{num}</span>
    </div>
  )
 }
  return (
    <div className='supplier-operation' >
        <div className='content'>
          <div className="supplier-header">
            <div>
              <ArticleTitle className="textSize-36 text-green-700 flex justify-center" position="center" title="VẬN HÀNH" />
              <span className="textSize-24 block text-center mt-4 md:text-justify text-green-800">Để có thể trở thành một nhà cung cấp và tiến hành kinh doanh các nông sản của mình, người dùng cần:</span>
            </div>
          </div>
          <div className='supplier-mobile-content my-4'>
             <div className='grid grid-cols-2' >
                    <div className='content-item'>
                        <div className='content-above'>
                            {contentIcon(icon1)}
                        </div>
                        <div>
                            {contentNumber("1")}
                        </div>
                        <div className='content-bottom'>
                            {contentText("Đăng ký tài khoản nhà cung cấp trên sàn")}
                        </div>
                    </div>
                    <div className='content-item'>
                        <div className='content-above'>
                            {contentIcon(icon2)}
                        </div>
                        <div>
                            {contentNumber("2")}
                        </div>
                        <div className='content-bottom'>
                            {contentText("Ký hợp đồng với hệ thống để đảm bảo tính rõ ràng về quyền lợi, nghĩa vụ của các bên")}
                        </div>
                    </div>
                    <div className='content-item col-span-2 my-2'>
                        <div className='content-above'>
                            {contentIcon(icon3)}
                        </div>
                        <div>
                            {contentNumber("3")}
                        </div>
                        <div className='content-bottom w-3/4'>
                            {contentText("Đưa các sản phẩm lên sàn tiếp cận với các cửa hàng")}
                        </div>
                    </div>
                    <div className='content-item'>
                        <div className='content-above'>
                            {contentIcon(icon4)}
                        </div>
                        <div>
                            {contentNumber("4")}
                        </div>
                        <div className='content-bottom'>
                            {contentText("Nhà cung cấp sẽ nhận được các yêu cầu đặt mua từ các cửa hàng, lựa chọn và tiến hành ký kết hợp đồng online")}
                        </div>
                    </div>
                    <div className='content-item'>
                        <div className='content-above'>
                            {contentIcon(icon5)}
                        </div>
                        <div>
                            {contentNumber("5")}
                        </div>
                        <div className='content-bottom'>
                            {contentText("Xuất hàng cho bên mua")}
                        </div>
                    </div>
             </div>
             <div className='ziczac-line'>
                <img src={line} className="line"/>
             </div>
          </div>
      </div>
    </div>
  )
}
