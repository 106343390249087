import { ArticleTitle, OverallPost } from "components/components";
import React from "react";
import { useState } from "react";
import posts from "routes/blogs/helper/posts";
import "./index.less";

const ContentBlogs = ()=> {
    const [featuredPost]=useState(posts.filter(ele=>ele.featured));
    const getUIOverallPost = (type,post) => {
        switch (type) {
            case "MAIN":
                return <div className="mainPost">
                {OverallPost({post:featuredPost.find(ele=>ele.featured==="MAIN"),type:"MAIN"})}
            </div>;
            case "SUB":
                const postSub = featuredPost.filter(ele=>ele.featured==="SUB");
                return <div className="mainPost1">
                {<div className="subPost">
                    <OverallPost post={postSub[0]} type="SUB"/>
                    <OverallPost post={postSub[1]} type="SUBN"/>
                </div>}
            </div>;
        
            default:
                return OverallPost({post:post,type:"NEWS"});
        }
    }

    return(
        <div className="contentBlogs">
            <div className="featuredPost">
                <ArticleTitle title={"Bài viết nổi bật".toUpperCase()} position="left" className="text-green-700 textSize-36 mb-10"/>
                <div className="content">
                        {getUIOverallPost("MAIN")}
                        {getUIOverallPost("SUB")}
                </div>
            </div>
            <div className="newPosts">
                <ArticleTitle title={"Bài viết mới nhất".toUpperCase()} position="left" className="text-green-700 textSize-36 my-10"/>
                <div className="content">
                    {posts.map((ele,index)=>(
                        <div className="post" key={index}>
                                {getUIOverallPost("NEWS",ele)}
                        </div>))
                    }
                </div>
            </div>
        </div>
    )
}

export default ContentBlogs;