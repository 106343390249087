import React from 'react'
import "./index.less"
import customerIcon from "asserts/icons/Ellipse_38.svg"
import supplierCustomer from "asserts/img/supplier_customer.png"
import { ArticleTitle } from 'components/components'
export default function CustomerSegment(props) {
  const { title, text } = props;
  return (
    <div className="supplier-customer 3xl:px-96 2xl:px-48">
      <div className="container-fluid">
          <div className='supplier-content'>
            <div className='left-content'> 
              <img src={supplierCustomer} alt="storeCustomer" className='w-full h-auto'></img>
            </div>
            <div className='right-content'>
              <div className="main-content">
                  <img src={customerIcon} className="supplier-icon"></img>
                  <ArticleTitle className="textSize-36 text-green-700 flex justify-end" position="right" title={title} />
                  <span className="textSize-24 block mt-4 text-justify" style={{color: "#515151"}}>{text}</span>
              </div>
            </div>
          </div>
          <div className="decoration-line-center"></div>
      </div>
    </div>
  )
}
