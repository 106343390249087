import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from 'reactstrap';
import "./index.less"

import bgCarousel_1 from "asserts/img/bg_carousel_1.png";
import bgCarousel_2 from "asserts/img/bg_carousel_2.png";
import bgCarousel_3 from "asserts/img/bg_carousel_3.png";
import bgCarousel_4 from "asserts/img/bg_carousel_4.png";
import bgCarousel_5 from "asserts/img/bg_carousel_5.png";
import coverCarousel from "asserts/icons/Rectangle_3.svg"

const items = [
  {
    src: bgCarousel_1,
    altText: 'Sàn thương mại điện tử B2B kết nối các doanh nghiệp sản xuất và các doanh nghiệp phân phối dựa trên dòng sản phẩm chính là nông sản.',
    key: 1,
  },
  {
    src: bgCarousel_2,
    altText: 'Các nông sản sẽ được kết nối với các doanh nghiệp phân phối, các căn tin, bếp ăn hay các doanh nghiệp thu mua có nhu cầu.',
    key: 2,
  },
  {
    src: bgCarousel_3,
    altText: 'Với sự tiến bộ của khoa học – công nghệ, Balance cung cấp giải pháp cho vấn đề thiếu nguồn thu và tình trạng bất cân bằng cung cầu.',
    key: 3,
  },
  {
    src: bgCarousel_4,
    altText: "Người nông dân sẽ được làm việc trực tiếp với các đơn vị thu mua mà không cần thông qua các khâu trung gian.",
    key: 4,
  },
  {
    src: bgCarousel_5,
    altText: 'Giảm thiểu nhiều rủi ro trong quá trình thương mại. Đồng thời đẩy nhanh quá trình chuyển đổi số ngành nông nghiệp Việt nói chung và lĩnh vực thương mại nông nghiệp nói riêng.',
    key: 5,
  },
];

const CarouselComponent = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} className="w-full 2xl:h-[1200px] h-[53vw]"/>
        {/* <div className="baseCover">
        </div> */}
        <div className="carouselCover text-white ">
          <div className='wrapContentCover sm:mb-0 mb-6'>
            <div className='contentCover'>
              <div className='text-left'>
                <h2 className="font-semibold lg:mb-3 lg:text-4xl 2xl:text-5xl md:text-4xl sm:text-2xl text-lg">Hệ Thống</h2>
                <h1 className="font-bold uppercase 2xl:mb-10 lg:text-[68px] 2xl:text-7xl md:text-5xl sm:text-3xl text-xl">Balance</h1>
              </div>
              <h2 className='lineEllipsis sm:font-bold font-light 2xl:text-3xl 2xl:leading-10 lg:text-2xl md:text-xl sm:text-lg text-xs'>{item.altText}</h2>
            </div>
          </div>
          <img src={coverCarousel} alt="coverCarousel" className='absolute w-[90%] h-[100%]'/>
        </div>
      </CarouselItem>
    );
  });
    return (
        <div className="carousel">
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                className="carouselComponent"
            >
                {slides}
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                  className="carouselIndicator -left-[50%] sm:bottom-[12%] bottom-[5%]"
                />
            </Carousel>
        </div>
    )
};

export default CarouselComponent;