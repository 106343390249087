import React, { useEffect, useState } from "react";
import "./HeaderBar.less";
import logoBalance from "asserts/img/logo_balance_1.svg";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const languageOption = [
  {
    label: "Vietnamese",
    value: "vn",
  },
  {
    label: "Enlish",
    value: "en",
  },
];

const LinkUnderLine = (props) => {
  const { children, path } = props;
  const location = useLocation();
  return (
    <div
      className={classNames(
        "wrapUnderLine textSize-14",
        !location.pathname.includes(path) && "hover-underline-animation"
      )}
    >
      {children}
      {location.pathname === "/"
        ? children?.props?.to === "/home" && <div className="underLine"></div>
        : location.pathname.includes(path) && <div className="underLine"></div>}
    </div>
  );
};

const HeaderBar = () => {
  const { t, i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [flagLanguage, setFlagLanguage] = useState("");

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    switch (i18n.language) {
      case "vn":
        setFlagLanguage("bg-[url('asserts/img/VietNamFLag.png')]");
        break;
      default:
        setFlagLanguage("bg-[url('asserts/img/VietNamFLag.png')]");
        break;
    }
  }, []);

  return (
    <div>
      <div className="headerBar max-w-[2200px] fixed top-0 left-[50%] -translate-x-1/2 z-[1000] bg-white">
        <div className="sideLeft">
          <div className="p-3">
            <img src={logoBalance} alt="Logo" className="logo-balance"/>
          </div>
          <a
            href="https://b2b.balance.ari.com.vn/#/auth/login"
            rel="noreferrer"
            target={"_blank"}
            className="login-text textSize-20 uppercase"
          >
            {t("Login")}
            <div className="left-line"></div>
          </a>
        </div>
        <div className="sideRight">
          <LinkUnderLine path="/home">
            <Link to="/home">
              <span className="text-green-700">{t("Home")}</span>
            </Link>
          </LinkUnderLine>
          <LinkUnderLine path="/store">
            <Link to="/store">
              <span className="text-green-700">{t("Store")}</span>
            </Link>
          </LinkUnderLine>
          <LinkUnderLine path="/supplier">
            <Link to="/supplier">
              <span className="text-green-700">{t("Supplier")}</span>
            </Link>
          </LinkUnderLine>
          <LinkUnderLine path="/blogs">
            <Link to="/blogs">
              <span className="text-green-700">{t("Blogs")}</span>
            </Link>
          </LinkUnderLine>
          {/* <div className="d-flex p-1">
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              className="bg-white"
            >
              <DropdownToggle
                caret
                className="bg-white text-green-800 border-none hover:bg-white hover:text-green-800 active:bg-white active:text-green-800 flex justify-center items-center"
              >
                <div
                  className={`w-6 h-6 rounded-[50%] overflow-hidden mr-2 bg-center ${flagLanguage}`}
                ></div>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header className="text-green-800 textSize-14">
                  {t("Languages")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem className="text-green-600 hover:text-green-700 active:bg-slate-400 textSize-14">
                  {t("Vietnamese")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem className="text-green-600 hover:text-green-700 active:bg-slate-400 textSize-14">
                  {t("English")}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div> */}
        </div>
      </div>
      <div className="w-full h-[80px]"></div>
    </div>
  );
};

export default HeaderBar;
