import { ArticleTitle } from "components/components";
import React, { useEffect, useState } from "react";
import { List } from "reactstrap";
import "./index.less";

const RoleComponent = () => {
  return (
    <div className="2xl:px-24">
      <div className="wrapRole">
        <div className="role">
          <div className="contentRole">
            <div className="lg:w-full xl:w-1/3 w-full ">
              {
                <ArticleTitle
                  className="textSize-36 text-green-700 sm:mb-2 lg:pb-[25px]"
                  position={"left"}
                  title="Tầm nhìn"
                />
              }
              <span className="textSize-24 lg:text-start text-center">
                Balance - đơn vị đồng hành cùng người Nông dân với mong muốn
                nâng cao niềm tự hào và giá trị của nông sản Việt.
              </span>
            </div>
            <div className="lg:w-full xl:w-1/3 w-full  lg:mt-8 md:mt-4 mt-3">
              <ArticleTitle
                className="textSize-36 text-green-700"
                position={"left"}
                title="SỨ MỆNH"
              />
              <List className="block lg:pt-4 mt-[11px]">
                {/* <ul>
                                <li className="textSize-24 list-disc lg:mt-4 mt-2">
                                    Cân bằng lại cán cân cung cầu.
                                </li>
                                <li className="textSize-24 list-disc lg:mt-4 mt-2">
                                    Nâng tầm nông sản Việt.
                                </li>
                            </ul> */}
                <span className="textSize-24 lg:text-start text-center">
                  Balance đem lại giá trị và lợi ích tốt nhất, kết nối nguồn
                  cung, cầu nhanh chóng, hiệu quả và tiết kiệm chi phí.
                </span>
              </List>
            </div>
          </div>
        </div>
        <div className="videoIframe">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/SwHt51cLDNc"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <div className="decorativeFrame bg-[#15803D]"></div>
        </div>
      </div>
    </div>
  );
};

export default RoleComponent;
