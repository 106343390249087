import React from "react";
import Benifits from "./components/BenifitComponent/Benifits";
import Carousel from "./components/CarouselComponent/Carousel";
import CustomerSegment from "./components/CustomerSegment/CustomerSegment";
import customerImg from "asserts/img/agriculture-iot.png"
import store_supplier from "asserts/icons/store_supplier.svg";
import store_quality from "asserts/icons/store_quality.svg";
import store_price from "asserts/icons/store_price.svg";
import store_payment from "asserts/icons/store_payment.svg";
import bg_partner from "asserts/img/store_partner.png";
import Operations from "./components/OperationComponent/Operations";
import RegisterForm from "./components/RegisterComponent/RegisterForm";
import PartnerCarousel from "components/components/PartnerComponent/PartnerCarousel";
import OperationsMobile from "./components/OperationComponent/Mobile/OperationMobile";
import { useWindowSize } from "components/hook/useWindowSize";
const Store = () => {
    const windowSize = useWindowSize();
    const items=[
        {
            icons:store_supplier,
            content:"Tiếp cận được nhiều nguồn cung"
        },
        {
            icons:store_quality,
            content:"Sản phẩm đảm bảo chất lượng"
        },
        {
            icons:store_price,
            content:"Giá cả mang tính cạnh tranh"
        },
        {
            icons:store_payment,
            content:"Cách thức mua hàng nhanh chóng, tiện lợi"
        },
    ] 
    return (
    <>
        <Carousel/>
        <CustomerSegment
            title="ĐỐI TƯỢNG"
            text="Các cửa hàng thu mua, các bếp ăn, hay căn tin, 
            những đối tượng có nhu cầu mua nông sản đảm bảo chất 
            lượng sẽ là các đối tượng hướng đến tài khoản cửa hàng 
            trên nền tảng."
        />
        <Benifits
            backgroundImage={customerImg}
            title="Lợi ích của cửa hàng"
            items={items}
        />
        { windowSize.width > 500 ? 
        <Operations/> : <OperationsMobile/>
        }
        <RegisterForm/>
        <PartnerCarousel
            backgroundImage={bg_partner}
        />
    </>)
}

export default Store;