import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from 'reactstrap';
import "./index.less"

import bgCarousel_1 from "asserts/img/partner_1.png";
import bgCarousel_2 from "asserts/img/partner_2.png";
import bgCarousel_3 from "asserts/img/partner_3.png";
import commentAvatar from "asserts/img/home/comment-avatar.png";
import DouQuoLeft from "asserts/img/home/double_quotation_l.png";
import DouQuoRight from "asserts/img/home/double_quotation_r.svg";

const items = [
  {
    src: bgCarousel_1,
    comment: 'Hiệu quả phân phối, rút ngắn quá trình kết nối và tối ưu hóa doanh thu là những điểm nổi bật mà chúng tôi nhận được khi sử dụng hệ thống Balance.',
    author:"",
    key: 1,
  },
  {
    src: bgCarousel_2,
    comment: 'Một trải nghiệm mang đầy tính mới mẻ - Balance sẽ là tương lai của ngành thương mại nông nghiệp Việt.',
    author:"",
    key: 2,
  },
  {
    src: bgCarousel_3,
    comment: 'Hệ thống là sự cải tiến của lĩnh vực thương mại điện tử và là điểm mới trong quá trình phân phối nông sản.',
    author:"",
    key: 3,
  },
];

const CarouselComponent = () => {
    const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.key}
      >
        <div className="carouselCover text-white">
          <div className='wrapContentCover'>
            <img src={DouQuoRight} alt="DouQuoLeft" className='w-8 h-8 sm:mr-8 mr-4 rotate-180'/>
            <div className='flex flex-col justify-center items-center'>
              <p className='text-center font-thin textSize-30 mb-10'>{item.comment}</p>
              <img src={item.src} alt={item.author} className="w-20 h-20"/>
            </div>
            <img src={DouQuoRight} alt="DouQuoRight" className='w-8 h-8 sm:ml-8 ml-4'/>
          </div>
        </div>
      </CarouselItem>
    );
  });
    return (
        <div className="partner-comment"
            >
            <div className="bg-[url('../asserts/img/home/comment-bg.png')] w-full xl:h-[760px] lg:h-[680px] md:h-[830px] h-[800px] bg-center bg-cover overflow-hidden" 
                
            >
                {/* <div className='w-full h-[850px] bgCoverBlur'></div> */}
            </div>
            <div className='w-full h-full comment-content'>
                <div className='header textSize-48 md:text-3xl'>{"Đối tác nói gì về chúng tôi"}</div>
                <div className='content'>
                    <img src={commentAvatar} alt="commentAvatar" className='comment-avatar xl:h-[500px] lg:h-[500px] h-[400px]'/>
                    <div className="carousel">
                        <Carousel
                            activeIndex={activeIndex}
                            next={next}
                            previous={previous}
                            className="carouselComponent"
                        >
                            {slides}
                            <CarouselIndicators
                              items={items}
                              activeIndex={activeIndex}
                              onClickHandler={goToIndex}
                              className="carouselIndicator -left-0 -bottom-5"
                            />
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CarouselComponent;