import React from "react";
import "./index.less";
import logoBalance2 from "asserts/img/logo_balance_2.png";
import { useTranslation } from "react-i18next";
import placeIcon from "asserts/icons/place.svg";
import phoneIcon from "asserts/icons/phone.svg";
import emailIcon from "asserts/icons/email.svg";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="content grid md:grid-cols-2 grid-cols-1 px-3">
        <img src={logoBalance2} alt="logo balance" className="footer-logo" />
        <div>
          <h3 className="contact textSize-36">{t("Contact")}</h3>

          <div className="contactSub">
            <img src={phoneIcon} alt="place icon" />
            <div>
              <span className="m-1 textSize-14">
                <a href="tel:(+84)987 765 926" className="hover:text-green-500">
                  (+84) 987 765 926 (Mr. Dũng)
                </a>
              </span>
              <br />
              <span className="m-1 textSize-14">
                <a href="tel:(+84)977 405 203" className="hover:text-green-500">
                  (+84) 977 405 203 (Mr. Trung)
                </a>
              </span>
            </div>
          </div>
          <div className="contactSub">
            <img src={emailIcon} alt="place icon" />
            <span className="m-1 textSize-14">
              <a
                href="mailto:contact.aribalance@gmail.com"
                className="hover:text-green-500"
              >
                contact.aribalance@gmail.com
              </a>
            </span>
          </div>
          <div className="contactSub">
            <img src={placeIcon} alt="place icon" />
            <span className="m-1 textSize-14">
              {t("Số 7 Cộng Hòa, Phường 4, Quận Tân Bình, TP HCM")}
            </span>
          </div>
        </div>
      </div>
      <span className="textSize-12 text-green-900 p-4 text-center">
        {`Copyright ©${new Date().getFullYear()} ARI Technology Co ., JSC. All rights reserved.`}
      </span>
    </div>
  );
};

export default Footer;
