import React from "react";
import {
    Navigate,
    Route,
    Routes
} from "react-router-dom";
import { useLocation } from "react-router-dom";
import Home from "./home";
import routes from "./routes";

const RouterComponents = () => {
    const location = useLocation();
    return (
        <Routes>
            {/* <Redirect from = "/" to = "/home" exact/> */}
            {/* {location.pathname === "/" && <Navigate to="/home" replace={true} />} */}
            <Route 
                exact
                path={"/"}
                element={<Home/>}
            />
            {
                routes.map(({path, Component }, key) =>{
                    // if ( isprotected ){

                    // }
                    return (
                        <Route 
                            exact
                            path={path}
                            key={key}
                            element={<Component/>}
                        />
                    )
                })
            }
        </Routes>
    )
};

export default RouterComponents;