import React from "react";
import Benifits from "./components/BenifitComponent/Benifits";
import Carousel from "./components/CarouselComponent/Carousel";
import CustomerSegment from "./components/CustomerSegment/CustomerSegment";
import supplierImg from "asserts/img/agriculture-iot-supplier.png"
import supplier from "asserts/icons/store_supplier.svg";
import supplier_quality from "asserts/icons/supplier_1.svg";
import supplier_price from "asserts/icons/store_price.svg";
import supplier_payment from "asserts/icons/supplier_2.svg";
import bg_partner from "asserts/img/store_partner.png";
import Operations from "./components/OperationComponent/Operations";
import RegisterForm from "./components/RegisterComponent/RegisterForm";
import PartnerCarousel from "components/components/PartnerComponent/PartnerCarousel";
import { useWindowSize } from "components/hook/useWindowSize";
import OperationsMobile from "./components/OperationComponent/Mobile/OperationMobile";
const Supplier = () => {
    const windowSize = useWindowSize()
    const items=[
        {
            icons:supplier,
            content:"Tiếp cận được nhiều nguồn thu mua"
        },
        {
            icons:supplier_quality,
            content:"Giá cả thu mua ổn định"
        },
        {
            icons:supplier_price,
            content:"Hạn chế khả năng ép giá"
        },
        {
            icons:supplier_payment,
            content:"Quy trình mua bán rõ ràng, minh bạch, nhanh chóng"
        },
    ] 
    return (
    <>
        <Carousel/>
        <CustomerSegment
            title="ĐỐI TƯỢNG"
            text="Nhà cung cấp hướng đến các đối tượng là người nông dân, 
            các chủ trang trại, các nhà sản xuất nông sản có nhu cầu tiếp 
            cận với nhiều nguồn thu hoặc kinh doanh các sản phẩm làm ra."
        />
        <Benifits
            backgroundImage={supplierImg}
            title="Lợi ích của Nhà cung cấp"
            items={items}
        />
        { windowSize.width > 500 ? 
        <Operations/> : <OperationsMobile/>
        }
        
        <RegisterForm/>
        <PartnerCarousel
            backgroundImage={bg_partner}
        />
    </>)
}
export default Supplier;