import { ArticleTitle, OverallPost } from "components/components";
import BGHeader from "asserts/img/blogs/header-bg.png";
import React, { useEffect, useState } from "react";
import customerIcon from "asserts/icons/Ellipse_38.svg";
import "./index.less";
import posts from "../helper/posts";
import { useLocation } from "react-router";
const BLogsBost = () => {
    const location = useLocation();
    const [amountRelatedPost,setAmountRelatedPost]= useState(window.innerWidth < 640?2:3);
    const [post,setPost]=useState(null);
    const [relatedPost,setRelatedPost]=useState(null);

    const nextPost = (postIndex) => {
        switch (postIndex) {
            case posts.length-1:
                return 0;
            default:
                return postIndex+1;
        }
    }

    const updateAmountRelatedPost = () =>{
        if(window.innerWidth < 640){
            amountRelatedPost!==2 && setAmountRelatedPost(2);
        }else{
            amountRelatedPost!==3 && setAmountRelatedPost(3);
        }
    }

    useEffect(()=>{
        window.addEventListener("resize",updateAmountRelatedPost)
    },[])

    useEffect(()=>{
        const {pathname} = location;
        if(pathname){
            const idPost = pathname.split("/")[2];
            const postIndex = posts.findIndex(ele => ele.id.toString()===idPost.toString());
            let relatedPostIndList=[];
            let index=postIndex;
            let i=0;
            while(i<amountRelatedPost){
                index=nextPost(index);
                relatedPostIndList.push(index)
                i++;
            }
            setRelatedPost(posts.filter((ele,index)=>relatedPostIndList.includes(index)));
            setPost(posts.find(ele => ele.id.toString()===idPost.toString()));
        }
    },[location,amountRelatedPost]);

    const CreateUIFromJson = (content) => {
        const {type,title,contentItem} = content;
        switch (type) {
            case "title":
                return<div className="title textSize-24">{contentItem}</div>;
            case "text":
                
                return<div className="text textSize-20">{contentItem}</div>;
            case "italics":
                
                return<div className="text textSize-20">{contentItem}</div>;
            case "img":
                return <div className="wrap-image">
                            <img src={contentItem} alt={title} className="image lx:w-3/5 md:w-4/5"/>
                        <span className="inline-block text-center textSize-20 font-extralight italic">{title}</span>
                    </div>
            case "list":
                return <div className="list textSize-20">
                    <h2 className="text">{title}</h2>
                    <ul className="list-content list-disc">
                        {
                            contentItem.map((ele,index)=><il className="text-item inline-block" key={index}>{ele}</il>)
                        }
                    </ul>
                </div>
            case "list-order":
                
                return <img src={contentItem} alt={title} className="image"/>
            default:
                break;
        }
    }


    return(<div className="blogpost">
        <div className="relative">
            <img src={BGHeader} alt="BGHeader" width={'100%'}/>
            <ArticleTitle title={"BLOG"} position="center_white" className="text-white textSize-48 absolute top-[10%] left-[50%] -translate-x-[50%]"/>
        </div>
        {post && (<div className="w-full h-auto">
            <div className="content">
                <div className="decorative "></div>
                <div className="header">
                    <img src={customerIcon} alt="customerIcon" className="customer-icon hidden md:block"></img>
                    <h1 className="title textSize-36">{post.title.toUpperCase()}</h1>
                    <div className="author-info">
                        <div className="info textSize-16">
                            <span className="author">{`By ${post.author}`}</span>
                            <div className="time">{post.time}</div>
                        </div>
                        <div className="contact"></div>
                    </div>
                </div>
                <div className="purport">
                    {post.content && <>
                        {
                            post.content?.map((ele,index)=><div key={index} className="item-post">{CreateUIFromJson(ele)}</div>)
                        }
                    </>}
                </div>
            </div>
            <div className="relatedPost">
                <div className="flex justify-center items-center">
                    <ArticleTitle title={"BÀI VIẾT LIÊN QUAN"} position="center_green" className="text-green-700 textSize-48 mr-3"/>
                </div>
                <div className="relatedPost-content">
                    {
                        relatedPost.map((ele,index)=><div key={index} className="sm:w-[30%] w-[48%]" onClick={()=>window.scrollTo(0, 0)}>
                            <OverallPost type="RELATED" post={ele}/>
                        </div>)
                    }
                </div>
            </div>
        </div>)}
    </div>);
}

export default BLogsBost;