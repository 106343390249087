import React from 'react'
import "./index.less"
import bg from "asserts/img/bg_agriculture.png"
export default function Carousel() {
  const backgroundStyle = {
    backgroundImage: `url(${bg})`, 
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  }
  return (
    <div id="store-carousel" style={{...backgroundStyle}} className="w-full 2xl:h-[1200px] h-[53vw]">
      {/* <div className='bg_cover'></div> */}
      <div className="wrapperContent">
        <div className='carousel-content'>
              <div className='carousel-title'>
                    <h2 className="font-bold textSize-48 lg:pb-6 sm:pb-3">Giới thiệu về</h2>
                    <h1 className="font-bold textSize-72 uppercase">CỬA HÀNG</h1>
              </div>
              <div className='carousel-description'>    
                <h2 className="lineEllipsis textSize-24">
                  Cửa hàng là 1 loại tài khoản người dùng trên sàn thương mại điện tử Balance. 
                  Với tài khoản này, người dùng sẽ thực hiện các chức năng liên quan đến việc tìm kiếm và mua hàng.
                </h2>
              </div> 
        </div>
      </div>
      
    </div>
  )
}
