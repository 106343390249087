import { pathRouting } from "./urlConfig";
import Home from "./home";
import Store from "./store";
import Supplier from "./supplier";
import BLogs, { BLogsBost } from "./blogs";

const pathRoutes = [
    {
        path: pathRouting.HOME,
        isprotected : false,
        name : "Home",
        Component: Home,
        render: true
    },
    {
        path: pathRouting.STORE,
        isprotected : false,
        name : "Store",
        Component: Store,
        render: true
    },
    {
        path: pathRouting.SUPPLIER,
        isprotected : false,
        name : "Supplier",
        Component: Supplier,
        render: true
    },
    {
        path: pathRouting.BLOGS,
        isprotected : false,
        name : "BLogs",
        Component: BLogs,
        render: true
    },
    {
        path: pathRouting.BLOGS_POST,
        isprotected : false,
        name : "BLogs",
        Component: BLogsBost,
        render: true
    }
];
export default pathRoutes;