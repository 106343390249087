import classNames from 'classnames';
import { ArticleTitle } from 'components/components';
import { useWindowSize } from 'components/hook/useWindowSize';
import React from 'react'
import "./index.less"
export default function Benifits({backgroundImage,title,text,items}) {
  const backgroundImageStyle = {
    width: "100%",
    height: "auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${backgroundImage})`
  };
  let windowSize = useWindowSize();
  return (
      <div style={{ ...backgroundImageStyle }} className="store-benefit 3xl:px-96 2xl:px-48">
          {/* <div className='bg_cover'></div> */}
          <div className='wrapperContent'>
            <div className="content">
                <ArticleTitle title={title} position="center_white" className="text-white textSize-36"/>
            </div>
            <div className="wrapItem grid lg:grid-cols-4 grid-cols-2">
                {
                    items && items.map((ele,index)=>(
                        <div className="item mb-4 lg:mb-0" key={index}>
                            <img src={ele.icons} alt="IconHome" className='itemIcon'/>
                            <span className={classNames("itemTitle font-semibold text-green-600",windowSize.width > 500 ? "textSize-16": "textSize-10")}>{ele.content}</span>
                        </div>
                    ))
                }
            </div>
          </div>
      </div>
  );
}
