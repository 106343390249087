const pathRouting = {
    HOME : "/home",
    STORE : "/store",
    BLOGS : "/blogs",
    BLOGS_POST : "/blogs/:post",
    SUPPLIER : "/supplier"
};

const urlService = {

};

export { pathRouting, urlService };