import React, { useState } from "react";
import "./index.less";
import backgroundImage from "asserts/img/farmbox-organic.png";
import { ArticleTitle } from "components/components";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Button, Col, Row } from "reactstrap";
import { useWindowSize } from "components/hook/useWindowSize";
import { send } from "emailjs-com";
import { Message } from "components/components";
export default function RegisterForm() {
  const [double, setDouble] = useState(false);
  const backgroundImageStyle = {
    width: "100%",
    height: "auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${backgroundImage})`,
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    storeName: Yup.string()
      .trim("Vui lòng nhập tên cửa hàng")
      .required("Vui lòng nhập tên cửa hàng"),
    managerName: Yup.string()
      .trim("Vui lòng nhập tên người quản lý")
      .required("Vui lòng nhập tên người quản lý"),
    address: Yup.string()
      .trim("Vui lòng nhập địa chỉ")
      .required("Vui lòng nhập địa chỉ"),
    email: Yup.string()
      .email("Email không hợp lệ")
      .required("Vui lòng nhập email"),
    phone: Yup.string()
      .required("Vui lòng nhập số điện thoại")
      .matches(phoneRegExp, "Số điện thoại không hợp lệ")
      .min(8, "Vui lòng nhập tối thiểu 8 ký tự số")
      .max(12, "Vui lòng nhập tối đa 12 ký tự số"),
  });
  let windowSize = useWindowSize();

  return (
    <div
      className="store-form 3xl:px-96 2xl:px-48"
      style={{ ...backgroundImageStyle }}
    >
      <div className="content grid lg:grid-cols-2 grid-cols-1">
        <div className="left-content mb-5 md:mb-0">
          <div>
            <ArticleTitle
              className="textSize-36 text-white flex justify-center lg:justify-start"
              position={windowSize.width > 768 ? "left_white" : "center_white"}
              title="ĐĂNG KÝ"
            />
            <span className="textSize-18 block mt-4" style={{ color: "white" }}>
              Hãy để Balance nâng tầm kinh doanh của bạn. Đăng ký thông tin để
              chúng tôi hỗ trợ bạn tốt hơn!
            </span>
          </div>
        </div>
        <div className="right-content">
          <div className="form">
            <Formik
              initialValues={{
                storeName: "",
                managerName: "",
                address: "",
                email: "",
                phone: "",
              }}
              onSubmit={(value, { resetForm }) => {
                setDouble(true);
                send(
                  "service_256a28d",
                  "store_template",
                  value,
                  "Hfdve-FvHB3hGu0EW"
                )
                  .then((response) => {
                    Message.success({ text: `Đăng ký cửa hàng thành công!` });
                    resetForm();
                    // console.log("SUCCESS!", response.status, response.text);
                  })
                  .catch((err) => {
                    Message.error({
                      text: `Đăng ký cửa hàng không thành công!`,
                    });
                  });
                setTimeout(() => {
                  setDouble(false);
                }, 2000);
              }}
              validationSchema={validationSchema}
            >
              {({ errors, touched }) => (
                <Form>
                  <Col xs={12}>
                    <Row className="input-field textSize-18">
                      <Field
                        type="text"
                        name="storeName"
                        placeholder="Tên cửa hàng *"
                        // pattern="[^\s]*"
                      />
                      {!!errors.storeName && !!touched.storeName ? (
                        <div className="text-red-700">{errors.storeName}</div>
                      ) : null}
                    </Row>
                    <Row className="input-field textSize-18">
                      <Field
                        type="text"
                        name="managerName"
                        placeholder="Tên người quản lý *"
                      />
                      {!!errors.managerName && !!touched.managerName ? (
                        <div className="text-red-700">{errors.managerName}</div>
                      ) : null}
                    </Row>
                    <Row className="input-field textSize-18">
                      <Field
                        type="text"
                        name="address"
                        placeholder="Địa chỉ *"
                      />
                      {!!errors.address && !!touched.address ? (
                        <div className="text-red-700">{errors.address}</div>
                      ) : null}
                    </Row>
                    <Row className="input-field textSize-18">
                      <Field type="email" name="email" placeholder="Email *" />
                      {!!errors.email && !!touched.email ? (
                        <div className="text-red-700">{errors.email}</div>
                      ) : null}
                    </Row>
                    <Row className="input-field textSize-18">
                      <Field
                        type="number"
                        name="phone"
                        placeholder="Số điện thoại *"
                      />
                      {!!errors.phone && !!touched.phone ? (
                        <div className="text-red-700">{errors.phone}</div>
                      ) : null}
                    </Row>
                    <div className="flex justify-center">
                      <Button
                        disabled={double}
                        type="submit"
                        className="submit-button textSize-18"
                      >
                        Đăng ký
                      </Button>
                    </div>
                  </Col>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
