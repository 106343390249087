import React, { useEffect, useState } from "react";
import "./index.less";
import logoBalance from "asserts/img/logo_balance_1.png";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { green } from "@mui/material/colors";

const languageOption = [
  {
    label: "Vietnamese",
    value: "vn",
  },
  {
    label: "Enlish",
    value: "en",
  },
];

const HeaderBarMobile = () => {
  const { t, i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenLanguage, setDropdownOpenLanguage] = useState(false);
  const [flagLanguage, setFlagLanguage] = useState("");
  const location = useLocation();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleLanguage = () =>
    setDropdownOpenLanguage((prevState) => !prevState);

  const LinkUnderLine = (props) => {
    const { children, path } = props;

    return (
      <div
        className={classNames(
          "wrapUnderLine textSize-14",
          location.pathname === "/"
            ? children?.props?.to === "/home" && "bg-green-700"
            : location.pathname.includes(path) && "bg-green-700"
        )}
      >
        {children}
      </div>
    );
  };
  useEffect(() => {
    switch (i18n.language) {
      case "vn":
        setFlagLanguage("bg-[url('asserts/img/VietNamFLag.png')]");
        break;
      default:
        setFlagLanguage("bg-[url('asserts/img/VietNamFLag.png')]");
        break;
    }
  }, []);

  return (
    <div>
      <div className="headerBar-mobile fixed top-0 left-0 z-[1000] bg-white">
        <div className="sideLeft-mobile">
          <div className="p-3">
            <img src={logoBalance} alt="Logo" className=" w-16 h-12 logo-balance" />
          </div>
          <a
            href="https://b2b.balance.ari.com.vn/#/auth/login"
            rel="noreferrer"
            target={"_blank"}
            className="login-text textSize-30 uppercase"
          >
            {t("Login")}
            <div className="left-line"></div>
          </a>
        </div>
        <div className="sideRight-mobile">
          <div className="d-flex p-1 ">
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              className="bg-white"
            >
              <DropdownToggle
                caret
                className="bg-white text-green-800 border-none hover:bg-white hover:text-green-800 active:bg-white active:text-green-800 flex justify-center items-center after:hidden"
              >
                <MenuIcon sx={{ color: green[700] }} />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem className="text-green-800 textSize-14 p-0 inline-block">
                  <LinkUnderLine path="/home">
                    <Link
                      to="/home"
                      className="w-full h-full d-flex justify-center items-center"
                    >
                      <span
                        className={classNames(
                          "text-green-700",
                          (location.pathname.includes("/home") ||
                            location.pathname === "/") &&
                            "text-white"
                        )}
                      >
                        {t("Home")}
                      </span>
                    </Link>
                  </LinkUnderLine>
                </DropdownItem>
                <DropdownItem className="text-green-600 textSize-14">
                  <LinkUnderLine path="/store">
                    <Link
                      to="/store"
                      className="w-full h-full d-flex justify-center items-center"
                    >
                      <span
                        className={classNames(
                          "text-green-700",
                          location.pathname.includes("/store") && "text-white"
                        )}
                      >
                        {t("Store")}
                      </span>
                    </Link>
                  </LinkUnderLine>
                </DropdownItem>
                <DropdownItem className="text-green-600 textSize-14">
                  <LinkUnderLine path="/supplier">
                    <Link
                      to="/supplier"
                      className="w-full h-full d-flex justify-center items-center"
                    >
                      <span
                        className={classNames(
                          "text-green-700",
                          location.pathname.includes("/supplier") &&
                            "text-white"
                        )}
                      >
                        {t("Supplier")}
                      </span>
                    </Link>
                  </LinkUnderLine>
                </DropdownItem>
                <DropdownItem className="text-green-600 textSize-14">
                  <LinkUnderLine path="/blogs">
                    <Link
                      to="/blogs"
                      className="w-full h-full d-flex justify-center items-center"
                    >
                      <span
                        className={classNames(
                          "text-green-700",
                          location.pathname.includes("/blogs") && "text-white"
                        )}
                      >
                        {t("Blogs")}
                      </span>
                    </Link>
                  </LinkUnderLine>
                </DropdownItem>
                {/* <DropdownItem onClick={toggle}>
                                <div className="d-flex p-1 justify-center items-center">
                                    <Dropdown isOpen={dropdownOpenLanguage} toggle={toggleLanguage} className="bg-white">
                                        <DropdownToggle caret 
                                            className="bg-white text-green-800 border-none hover:bg-white hover:text-green-800 active:bg-white active:text-green-800 flex justify-center items-center" 
                                        >
                                        <div className={`w-6 h-6 rounded-[50%] overflow-hidden mr-2 bg-center ${flagLanguage}`}>
                                        </div>
                                        </DropdownToggle>
                                        <DropdownMenu >
                                        <DropdownItem header className="text-green-800 textSize-14">{t("Languages")}</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem className="text-green-600 hover:text-green-700 active:bg-slate-400 textSize-14">{t("Vietnamese")}</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem className="text-green-600 hover:text-green-700 active:bg-slate-400 textSize-14">{t("English")}</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </DropdownItem> */}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="w-full h-[85px]"></div>
    </div>
  );
};

export default HeaderBarMobile;
