import { ArticleTitle } from "components/components";
import React, { useEffect, useRef } from "react";
import { List } from "reactstrap";
import "./index.less"
import Home_store from "asserts/img/home/home_store.png";
import home_supplier from "asserts/img/home/supplier.png";
import { useNavigate } from "react-router";
import { pathRouting } from "routes/urlConfig";
import iconHalfUpCircle from "asserts/icons/half-up-circle.svg"
// import iconHalfDownCircle from "asserts/icons/half-up-circle.svg"
import iconCircle from "asserts/icons/circle.svg"

const IntroduceComponent = () => {
    const navigate = useNavigate();

      
    return(
        <div className="wrapIntroduce">
            <div className="introduce">
                <div className="store">
                    <img src={Home_store} alt="Home_store" className="image"/>
                    <div className="content">
                        <ArticleTitle title={"VỀ CỬA HÀNG"} position="center_white" className="text-white textSize-36"/>
                        <span className="text-white contentText lg:leading-10 sm:leading-7 leading-5 lg:text-left max:text-center textSize-24 textEllipsis">Cửa hàng là một đối tượng người dùng trên Balance, Cửa hàng ở đây là các doanh nghiệp phân phối, các doanh nghiệp thu mua, cũng có thể là các bếp ăn, căn tin… Đây là đối tượng người dùng có nhu cầu mua nông sản.</span>
                        <button className="button textSize-20" onClick={()=>{
                            window.scrollTo(0,0)
                            navigate(pathRouting.STORE);
                            }}
                            >Tìm hiểu thêm</button>
                        <div className="decoration bg-bg-green sm:-translate-x-[12.5%]"></div>
                        <img src={iconCircle} alt="iconCircle" className="absolute left-0 sm:inline-block hidden bottom-0 -translate-x-1/2 translate-y-1/2 z-20"/>
                    </div>
                </div>
                <div className="supplier">
                    <div className="content">
                        <ArticleTitle title={"VỀ NHÀ CUNG CẤP"} position="center_white" className="text-white textSize-36"/>
                        <span className="text-white contentText lg:leading-10 sm:leading-7 leading-5 lg:text-start max:text-center textSize-24 textEllipsis">Nhà cung cấp là một đối tượng người dùng trên Balance, Nhà cung cấp hướng đến các chủ trang trại, người nông dân, các nhà sản xuất nông sản... Nhà cung cấp là người dùng thực hiện các chức năng bán hàng trên Balance.</span>
                        <button className="button textSize-20" 
                            onClick={()=>{
                                window.scrollTo(0,0)
                                navigate(pathRouting.SUPPLIER);
                                }}
                        >Tìm hiểu thêm</button>
                        <div className="decoration bg-bg-green sm:translate-x-[12.5%]"></div>
                    </div>
                    <img src={home_supplier} alt="home_supplier" className="image"/>
                </div>
            </div>
            <img src={iconHalfUpCircle} alt="iconCircle" className="absolute left-1/2 sm:inline-block hidden top-0 -translate-x-1/2 z-20"/>
            <img src={iconCircle} alt="iconCircle" className="absolute left-1/2 md:bottom-10 sm:inline-block hidden bottom-3 -translate-x-1/2 z-20"/>
            <div className="decoration-line-center"></div>
            <div className="decoration-line"></div>
        </div>
    )
};

export default IntroduceComponent;