import React from 'react'
import "./index.less"
import customerIcon from "asserts/icons/Ellipse_38.svg"
import storeCustomer from "asserts/img/store-customer.png"
import { ArticleTitle } from 'components/components'
export default function CustomerSegment(props) {
  const { title, text } = props;
  return (
    <div className="store-customer 3xl:px-96 2xl:px-48">
      <div className="container-fluid">
          <div className='customer-content'>
            <div className='left-content'> 
              <div className="main-content ">
                  <img src={customerIcon} className="decoration-icon-left"></img>
                  <ArticleTitle className="textSize-36 text-green-700" position="left" title={title} />
                  <span className="textSize-24 block mt-4 text-justify" style={{color: "#515151"}}>{text}</span>
                  <img src={customerIcon} className="decoration-icon-right"></img>
              </div>
            </div>
            <div className='right-content'>
              <img src={storeCustomer} alt="storeCustomer" className='w-full h-auto'></img>
            </div>
          </div>
          <div className="decoration-line-center"></div>
      </div>
    </div>
  )
}
