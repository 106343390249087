import React from "react";
import BGHeader from "asserts/img/blogs/header-bg.png"
import { ArticleTitle } from "components/components";
import { ContentBlogs } from "./components";

const Blogs = () =>{

    
    return <div>
        <div className="relative">
            <img src={BGHeader} alt="BGHeader" width={'100%'}/>
            <ArticleTitle title={"BLOG"} position="center_white" className="text-white sm:text-5xl text-2xl absolute top-[10%] left-[50%] -translate-x-[50%]"/>
        </div>
        <ContentBlogs/>
    </div>
}

export default Blogs;