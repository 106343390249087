import React from 'react'
import icon1 from "asserts/icons/store-operation.svg"
import icon2 from "asserts/icons/store-operation-1.svg"
import icon3 from "asserts/icons/store-operation-2.svg"
import icon4 from "asserts/icons/store-operation-3.svg"
import icon5 from "asserts/icons/store-operation-4.svg"
import line from "asserts/img/Line_14.png"
import "./index.less";
import { ArticleTitle } from 'components/components';
export default function OperationsMobile(props) {
 const contentIcon = (iconLink) => {
  return (
    <>
      <div className="icon-content bg-green-600" >
        <img src={iconLink} alt="" className='md:w-auto w-6'/>
      </div>
    </>
  )
 }
 const contentText = (text) => {
  return (
    <div className='text-content'>
      <span className="textSize-16 text-green-600">{text}</span>
    </div>
      )
 }
 const contentNumber = (num) => {
  return (
    <div className="content-number" style={{backgroundColor: "#F9B509", color: "white"}}>
            <span>{num}</span>
    </div>
  )
 }
 const ColorlibStepIcon = (props) => {
    const icons = {
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
    };
    return icons[String(props.icon)] ? (<div className="stepper-content" style={{backgroundColor: "#F9B509", color: "white"}}>
            {icons[String(props.icon)]}
      </div>
    ) : <></>
  }
  const steps = [
    <>
      <div className='content-above'>
          {contentIcon(icon1)}
      </div>
      <div>
          {contentNumber("1")}
      </div>
      <div className='content-bottom'>
          {contentText("Đăng ký tài khoản cửa hàng trên hệ thống")}
      </div>
    </>,
    <>
      <div className='content-above'>
        {contentText("Tìm kiếm sản phẩm có nhu cầu mua")}
      </div>
      <div className='content-bottom'>
        {contentIcon(icon2)}
      </div>
    </>,
     <>
     <div className='content-above'>
         {contentIcon(icon3)}
     </div>
     <div className='content-bottom'>
         {contentText('Hệ thống sẽ trả về nhiều kết quả, khi đó người dùng Cửa hàng sẽ lựa chọn mặt hàng với mức giá phù hợp')}
     </div>
   </>,
    <>
    <div className='content-above'>
      {contentText('Tiến hành ký kết hợp đồng online với các Nhà cung cấp')}
    </div>
    <div className='content-bottom'>
      {contentIcon(icon4)}
    </div>
  </>,
     <>
     <div className='content-above'>
         {contentIcon(icon5)}
     </div>
     <div className='content-bottom'>
         {contentText('Nhận hàng')}
     </div>
   </>,
  ];
  const backgroundImageStyle = {
    width: "100%",
    height: "auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 300px",
    backgroundPosition: "center",
    backgroundImage: `url(${line})`
  };

  return (
    <div className='store-operation' >
        <div className='content'>
          <div className="store-header">
            <div>
              <ArticleTitle className="textSize-36 text-green-700 flex justify-center" position="center" title="VẬN HÀNH" />
              <span className="textSize-24 block text-center mt-4 md:text-justify text-green-800">Để trở thành một cửa hàng và tiến hành mua hàng trên Balance, người dùng cần:</span>
            </div>
          </div>
          <div className='store-mobile-content mt-4'>
             <div className='grid grid-cols-2' >
                    <div className='content-item'>
                        <div className='content-above'>
                            {contentIcon(icon1)}
                        </div>
                        <div>
                            {contentNumber("1")}
                        </div>
                        <div className='content-bottom'>
                            {contentText("Đăng ký tài khoản cửa hàng trên hệ thống")}
                        </div>
                    </div>
                    <div className='content-item'>
                        <div className='content-above'>
                            {contentIcon(icon2)}
                        </div>
                        <div>
                            {contentNumber("2")}
                        </div>
                        <div className='content-bottom'>
                            {contentText("Tìm kiếm sản phẩm có nhu cầu mua")}
                        </div>
                    </div>
                    <div className='content-item col-span-2 my-2'>
                        <div className='content-above'>
                            {contentIcon(icon3)}
                        </div>
                        <div>
                            {contentNumber("3")}
                        </div>
                        <div className='content-bottom w-3/4'>
                            {contentText("Hệ thống sẽ trả về nhiều kết quả, khi đó người dùng Cửa hàng sẽ lựa chọn mặt hàng với mức giá phù hợp")}
                        </div>
                    </div>
                    <div className='content-item'>
                        <div className='content-above'>
                            {contentIcon(icon4)}
                        </div>
                        <div>
                            {contentNumber("4")}
                        </div>
                        <div className='content-bottom'>
                            {contentText("Tiến hành ký kết hợp đồng online với các Nhà cung cấp")}
                        </div>
                    </div>
                    <div className='content-item'>
                        <div className='content-above'>
                            {contentIcon(icon5)}
                        </div>
                        <div>
                            {contentNumber("5")}
                        </div>
                        <div className='content-bottom'>
                            {contentText("Nhận hàng")}
                        </div>
                    </div>
             </div>
             <div className='ziczac-line'>
                <img src={line} className="line"/>
             </div>
          </div>
      </div>
    </div>
  )
}
