import classNames from "classnames";
import React from "react";
import "./index.less"

const ArticleTitle = ({title,position,className }) => {
    const selectPosiotion = () => {
        switch(position){
            case "right":
                return "border_right_50";
            case "left":
                return "border_left_50";
            case "center_white":
                return "border_center_50_white";
            case "center_green":
                return "border_center_50_green";
            case "left_white":
                return "border_left_50_white";
            default:
                return "border_center_50";
        }
    }
    return (
        <div className={className}>
            <div className={classNames("inline-block", selectPosiotion())}>
                <h2 className="uppercase font-semibold">
                    {title}
                </h2>
                {/* <div className="w-1/2 h-[3px] bg-green-700 items-end"></div> */}
            </div>
        </div>
    )
};

export default ArticleTitle;