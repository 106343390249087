const Message = {
  success: ({
    text,
    title = "Thành công",
    cancelButtonText = "Đóng",
    showCloseButton = false,
    showCancelButton = true,
    showConfirmButton = false,
    padding = 0,
    timer = 3000,
    reverseButtons = true,
  }) =>
    import("sweetalert2").then(({ default: Swal }) =>
      Swal.fire({
        icon: "success",
        timer,
        title,
        text,
        cancelButtonText,
        showCloseButton,
        showCancelButton,
        showConfirmButton,
        padding,
        reverseButtons,
      })
    ),
  error: ({
    text,
    title = "Thất bại",
    cancelButtonText = 'Đóng',
    showCloseButton = true,
    showCancelButton = true,
    showConfirmButton = false,
    padding = 0,
    reverseButtons = true,
  }) =>
    import("sweetalert2").then(({ default: Swal }) =>
      Swal.fire({
        icon: "error",
        title,
        text,
        cancelButtonText,
        showCloseButton,
        showCancelButton,
        showConfirmButton,
        padding,
        focusCancel: showCancelButton,
        reverseButtons,
      })
    ),
};
export default Message;
