import React from 'react'
import "./index.less"
import bg from "asserts/img/bg_agriculture.png"
export default function Carousel() {
  const backgroundStyle = {
    backgroundImage: `url(${bg})`, 
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  }
  return (
    <div id="supplier-carousel" style={{...backgroundStyle}} className="w-[full] 2xl:h-[1200px] h-[53vw]">
      {/* <div className='bg_cover'></div> */}
      <div className="wrapperContent">
        <div className='carousel-content'>
              <div className='carousel-title'>
                <h2 className="font-bold textSize-48 lg:pb-6 sm:pb-3">Giới thiệu về</h2>
                <h1 className="font-bold textSize-72 uppercase">NHÀ CUNG CẤP</h1>
              </div>
              <div className='carousel-description'>
                <h2 className="lineEllipsis textSize-24">
                Nhà cung cấp là một loại tài khoản trên sàn Balance, 
                thực hiện các chức năng liên quan đến người bán, 
                bao gồm việc đưa các sản phẩm lên sàn tiếp cận với người mua, 
                tạo hợp đồng và xuất hàng.
                </h2>
              </div>
              
        </div>
      </div>
      
    </div>
  )
}
