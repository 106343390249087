import React from "react";
import { Link } from "react-router-dom";
import { pathRouting } from "routes/urlConfig";
import "./index.less";

const UpperCaseString = (str="") => {
    if(typeof str === "string"){
        return str.charAt(0).toUpperCase() + str.slice(1);;
    }
}

const OverallPost = (props) => {
    const {post,type} = props;
    const GetOverallPostUI = () => {

        switch (type) {
            case "MAIN":
                return (
                <div className="overrallPostMain">
                    <Link to={`${pathRouting.BLOGS}/${post.id}`} className="hover:text-inherit h-full flex flex-col justify-between">
                        <img src={post.img} className="rounded-2xl" alt="BGHeader"/>
                        <div className="wrapTitle">
                            <span className="textSize-20 textEllipsis text-green-700 font-bold">{post.title.toUpperCase()}</span>
                            <div className="textSize-12 flex text-green-700 max:hidden">
                                <span className="author">{`By ${post.author} - `}</span>
                                <div className="time">{post.time}</div>
                            </div>
                        </div>
                    </Link>
                </div>);
        
            case "SUB":
                return (
                    <div className="overrallPostSub">
                        <Link to={`${pathRouting.BLOGS}/${post.id}`} className="hover:text-inherit h-full flex flex-col justify-between">
                            <img src={post.img} className="rounded-2xl pb-2 h-[50%] flex-shrink-0" alt="BGHeader"/>
                            <div className="h-full pt-2">
                                <div className="wrapTitle">
                                    <span className="textSize-18 textEllipsis font-bold">{post.title.toUpperCase()}</span>
                                </div>
                                <div className="wrapText">
                                    <span className="textEllipsis textSize-14 font-normal">{post.content.find(ele=> ele.type === "text").contentItem}</span>
                                </div>
                            </div>
                            
                        </Link>
                    </div>);
            case "SUBN":
                return(
                    <div className="overrallPostSub">
                        <Link to={`${pathRouting.BLOGS}/${post.id}`} className="hover:text-inherit h-full flex flex-col justify-between ">
                            <div>
                                <div className="wrapTitle">
                                    <span className="textSize-18 textEllipsis font-bold">{post.title.toUpperCase()}</span>
                                </div>
                                <div className="wrapText">
                                    <span className="textEllipsis textSize-14 font-normal">{post.content.find(ele=> ele.type === "text").contentItem}</span>
                                </div>
                            </div>
                            <img src={post.img} className="rounded-2xl pt-2 h-[50%]" alt="BGHeader"/>
                        </Link>
                    </div>);
            case "RELATED":
                return (
                    <div className="overrallPostSub">
                    <Link to={`${pathRouting.BLOGS}/${post.id}`} className="hover:text-inherit h-full flex flex-col justify-between">
                        <img src={post.img} className="rounded-2xl pb-2 xl:h-[350px] lg:[300px] md:h-[250px] sm:h-[150px] h-[150px] w-full" alt="BGHeader"/>
                        <div className="wrapTitle">
                            <span className="textSize-18 textEllipsis font-bold">{UpperCaseString(post.title.toLowerCase())}</span>
                        </div>
                    </Link>
                </div>);
            default:
                return(
                    <div className="overrallPostNew">
                        <Link to={`${pathRouting.BLOGS}/${post.id}`} className="hover:text-inherit h-full flex flex-col justify-between">
                            <img src={post.img} className="rounded-2xl pb-2 2xl:h-[300px] xl:h-[200px] md:h-[250px] flex-shrink-0" alt="BGHeader"/>
                            <div className="flex-1 flex flex-col justify-between">
                                <div className="wrapTitle">
                                    <span className="textSize-18 textEllipsis font-bold">{UpperCaseString(post.title.toLowerCase())}</span>
                                </div>
                                <div className="wrapText">
                                    <span className="textEllipsis textSize-14 font-normal">{post.content.find(ele=> ele.type === "text").contentItem}</span>
                                </div>
                            </div>
                        </Link>
                    </div>);
        }
    }
    return (
        GetOverallPostUI()
    )
}

export default OverallPost;