import React from "react";
import bgCarousel_1 from "asserts/img/partner_1.png";
import bgCarousel_2 from "asserts/img/partner_2.png";
import bgCarousel_3 from "asserts/img/partner_3.png";
import bgCarousel_4 from "asserts/img/partner_4.png";
import bgCarousel_5 from "asserts/img/partner_5.jpg";
import bgCarousel_6 from "asserts/img/partner_6.png";
import { ArticleTitle } from 'components/components';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./index.less";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import { useWindowSize } from "components/hook/useWindowSize";
const items = [
    {
      src: bgCarousel_1,
      altText: 'Sàn thương mại điện tử B2B kết nối các doanh nghiệp sản xuất và các doanh nghiệp phân phối dựa trên dòng sản phẩm chính là nông sản.',
      key: 1,
    },
    {
      src: bgCarousel_2,
      altText: 'Các nông sản sẽ được kết nối với các doanh nghiệp phân phối, các căn tin, bếp ăn hay các doanh nghiệp thu mua có nhu cầu.',
      key: 2,
    },
    {
      src: bgCarousel_3,
      altText: 'Các nông sản sẽ được kết nối với các doanh nghiệp phân phối, các căn tin, bếp ăn hay các doanh nghiệp thu mua có nhu cầu. Với sự tiến bộ của khoa học – công nghệ, Balance cung cấp giải pháp cho vấn đề thiếu nguồn thu và tình trạng bất cân bằng cung cầu.',
      key: 3,
    },
    {
      src: bgCarousel_4,
      altText: 'Sàn thương mại điện tử B2B kết nối các doanh nghiệp sản xuất và các doanh nghiệp phân phối dựa trên dòng sản phẩm chính là nông sản.',
      key: 4,
    },
    {
      src: bgCarousel_5,
      altText: 'Các nông sản sẽ được kết nối với các doanh nghiệp phân phối, các căn tin, bếp ăn hay các doanh nghiệp thu mua có nhu cầu.',
      key: 5,
    },
    {
      src: bgCarousel_6,
      altText: 'Các nông sản sẽ được kết nối với các doanh nghiệp phân phối, các căn tin, bếp ăn hay các doanh nghiệp thu mua có nhu cầu. Với sự tiến bộ của khoa học – công nghệ, Balance cung cấp giải pháp cho vấn đề thiếu nguồn thu và tình trạng bất cân bằng cung cầu.',
      key: 6,
    }
  ];
export default function PartnerCarousel(props) {
  const { backgroundImage } = props;
  const backgroundImageStyle = {
    width: "100%",
    height: "auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${backgroundImage})`
  };
  let windowSize = useWindowSize();
  return (
    <div className="partner 3xl:px-96 2xl:px-48 flex flex-col justify-center items-center" style={{ ...backgroundImageStyle }}>    
            <div className="mb-10 ">
                <ArticleTitle className="textSize-48 text-white flex justify-center" position="center_white" title="ĐỐI TÁC" />
            </div>  
            <div className="partner-carousel">
                <Swiper
                    slidesPerView={ (windowSize?.width > 1536) ? 4 : (windowSize?.width > 1280) ?  3 : windowSize?.width > 500 ? 2 : 1}
                    spaceBetween={30}
                    slidesPerGroup={1}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    pagination={{
                    clickable: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                  {items.map((item) => { 
                    return (
                      <SwiperSlide>
                        <div className='partner-logo'>
                          <img src={item.src} alt={item.altText} className=" lg:w-44 md:w-40 w-32"/>
                        </div>
                    </SwiperSlide>
                    )
                   })}
                </Swiper>
          </div>
        </div>
          
    
  )
}
