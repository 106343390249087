import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "multiLanguage/i18n"
import "styles/index.less"
import ScrollToTop from "components/utils/ScrollToTop";

ReactDOM.render(
    <BrowserRouter >
        <ScrollToTop/>
        <App />
    </BrowserRouter >
    , document.getElementById("root"));