import React from "react";
// import { Link, Outlet } from "react-router-dom";
import RouterComponents from "../routes"
import { Footer, HeaderBar } from "components";
import "./index.less"
import HeaderBarMobile from "components/HeaderBar/Mobile/HeaderBarMobile";
import { useWindowSize } from "components/hook/useWindowSize";

const Layout = () => {
    let windowSize = useWindowSize();
    return (
        <div>
            <div className="max-w-[2200px] m-auto overflow-hidden relative">
                {windowSize.width > 790 ? <HeaderBar/> :  <HeaderBarMobile/>}
                <div className="min-h-screen">
                    <RouterComponents/>
                </div>
                <Footer/>
            </div>
        </div>
    )
}
export default Layout;