import { Carousel } from "components/components";
import React from "react";
import {
  BenefitComponent,
  IntroduceComponent,
  PartnerComment,
  RoleComponent,
} from "./components";
import agricultureBgImage from "asserts/img/agricultureBgImage.png";
import IconHome from "asserts/icons/home.svg";
import IconSuplier from "asserts/icons/supplier.svg";
import IconProduct from "asserts/icons/product.svg";
import PartnerCarousel from "components/components/PartnerComponent/PartnerCarousel";
import bg_partner from "asserts/img/home_partner_bg.png";

const Home = () => {
  const items = [
    {
      icons: IconHome,
      content: "Sự hài lòng của các cửa hàng",
    },
    {
      icons: IconSuplier,
      content: "Sự tin tưởng của các nhà cung cấp",
    },
    {
      icons: IconProduct,
      content: "Chất lượng của sản phẩm",
    },
  ];
  return (
    <>
      <div className="wrapHome ">
        <Carousel />
        <RoleComponent />
        <BenefitComponent
          backgroundImage={agricultureBgImage}
          title="Lợi ích dành cho người bán"
          text="Balance lấy sự hài lòng của các cửa hàng, sự tin tưởng của các nhà cung cấp và chất lượng của nông sản làm định hướng hoạt động của nền tảng."
          items={items}
        />
        <div className="relative w-full">
          <IntroduceComponent />
          <PartnerComment />
          <div className="decoration-line sm:inline-block hidden border-l-[0.5px] absolute h-full border-[#1A6734] opacity-50 3xl:left-96 2xl:left-48 xl:left-20 md:left-10 sm:left-5 left:3 top-0"></div>
        </div>
        <PartnerCarousel backgroundImage={bg_partner} />
      </div>
    </>
  );
};

export default Home;
