import React from 'react'
import icon1 from "asserts/icons/store-operation.svg"
import icon2 from "asserts/icons/store-operation-1.svg"
import icon3 from "asserts/icons/store-operation-2.svg"
import icon4 from "asserts/icons/store-operation-3.svg"
import icon5 from "asserts/icons/store-operation-4.svg"
import line from "asserts/icons/Line.svg"
import "./index.less";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { ArticleTitle } from 'components/components';
export default function Operations(props) {
 const contentIcon = (iconLink) => {
  return (
    <>
      <div className="icon-content bg-green-600" >
        <img src={iconLink} alt="" className='xl:w-auto md:w-10 w-8'/>
      </div>
    </>
  )
 }
 const contentText = (text) => {
  return (
    <div className='text-content'>
      <span className="textSize-16 text-green-600">{text}</span>
    </div>
      )
 }
 const ColorlibStepIcon = (props) => {
    const icons = {
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
    };
    return icons[String(props.icon)] ? (<div className="stepper-content" style={{backgroundColor: "#F9B509", color: "white"}}>
            {icons[String(props.icon)]}
      </div>
    ) : <></>
  }
  const steps = [
    <>
      <div className='content-above'>
          {contentIcon(icon1)}
      </div>
      <div className='content-bottom'>
          {contentText("Đăng ký tài khoản cửa hàng trên hệ thống")}
      </div>
    </>,
    <>
      <div className='content-above'>
        {contentText("Tìm kiếm sản phẩm có nhu cầu mua")}
      </div>
      <div className='content-bottom'>
        {contentIcon(icon2)}
      </div>
    </>,
     <>
     <div className='content-above'>
         {contentIcon(icon3)}
     </div>
     <div className='content-bottom'>
         {contentText('Hệ thống sẽ trả về nhiều kết quả, khi đó người dùng Cửa hàng sẽ lựa chọn mặt hàng với mức giá phù hợp')}
     </div>
   </>,
    <>
    <div className='content-above'>
      {contentText('Tiến hành ký kết hợp đồng online với các Nhà cung cấp')}
    </div>
    <div className='content-bottom'>
      {contentIcon(icon4)}
    </div>
  </>,
     <>
     <div className='content-above'>
         {contentIcon(icon5)}
     </div>
     <div className='content-bottom'>
         {contentText('Nhận hàng')}
     </div>
   </>,
  ];

  return (
    <div className='store-operation 3xl:px-96 2xl:px-48' >
        <div className='content'>
          <div className="store-header">
            <div>
              <ArticleTitle className="textSize-36 text-green-700 flex justify-center" position="center" title="VẬN HÀNH" />
              <span className="textSize-24 block mt-4 text-justify text-green-800">Để trở thành một cửa hàng và tiến hành mua hàng trên Balance, người dùng cần:</span>
            </div>
          </div>
          <Box sx={{ width: '100%', alignItems: "center",justifyItems: "center", display: "flex" }}>
            <Stepper alternativeLabel className='store-stepper'>
              {steps.map((label, index) => (
                (label !== "") && 
                (<Step key={index}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>)
              ))}
              <div className='stepper-line'>
                <img src={line} className="line w-full"/>
              </div>
            </Stepper>
          </Box>
      </div>
    </div>
  )
}
